import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Actions = () => {
  return (
    <Row className="text-center mt-5">
      <Col>
        <ButtonGroup>
          <Button
            type="submit"
            form="gameform"
            variant="secondary"
            // Here should be template handling - now in CreateGameForm we are sending isTemplate with same value as we got from useGameInfo hook in GameEditorPage.
            // onClick={() => setIsTemplate(true)}
          >
            Uložit jako šablonu
          </Button>
          <Button
            type="submit"
            form="gameform"
            // onClick={() => setIsTemplate(false)}
          >
            Uložit hru
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default Actions;
