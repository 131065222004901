import React from "react";
import { TeamInfoType } from "../../api/datatypes";
import MapWithMarkers from "./MapWithMarkers";

interface TeamsMapProps {
  teams: TeamInfoType[];
}

const TeamsMap = (props: TeamsMapProps) => {
  const teamToPopupText = (team: TeamInfoType): JSX.Element => {
    return (
      <>
        <strong>{team.name}</strong> <br />
        Hotových úkolů: {team.completedQuests} <br />
        SOS kontakt: {team.teamContact.sosName} ({team.teamContact.sosPhone})
      </>
    );
  };

  return (
    <MapWithMarkers<TeamInfoType>
      markerItems={{
        list: props.teams,
        itemToCoords: (item: TeamInfoType) => item.coords,
        itemToPopupText: teamToPopupText,
        adjustBounds: true,
      }}
    />
  );
};
export default TeamsMap;
