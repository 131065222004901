import React from "react";
import Quest from "./Quest";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import { QuestInfoType } from "../../api/datatypes";
import QuestsMap from "../map/QuestsMap";

function TabQuests(props: {
  tabActive: boolean;
  finishCoords: [number, number] | null;
  quests: Array<QuestInfoType>;
}) {
  const quests = props.quests.map((quest) => {
    return (
      <ListGroup.Item key={quest.id}>
        <Quest quest={quest} type={quest.type} />
      </ListGroup.Item>
    );
  });

  return (
    <Row>
      {props.quests.length > 0 && props.tabActive ? (
        <>
          <Col
            className="scrollable-col"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            xxl={4}
          >
            <ListGroup variant="flush">{quests}</ListGroup>
          </Col>
          <Col className="p-0" xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
            <QuestsMap
              quests={props.quests}
              gameFinishCoords={
                props.finishCoords ? props.finishCoords : undefined
              }
              adjustBounds={true}
            />
          </Col>
        </>
      ) : (
        <Col xs={12} className="text-center mt-4">
          <p>Ve hře zatím nejsou žádné úkoly.</p>
        </Col>
      )}
    </Row>
  );
}

export default TabQuests;
