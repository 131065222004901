import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useGamesList } from "../api/game";
import HandleError from "./HandleError";
import { GameBriefInfoType } from "../api/datatypes";
import { useHistory } from "react-router-dom";

function formatGameString(game: GameBriefInfoType | string): string {
  if (typeof game === "string") {
    return game;
  }
  let result = game.name;
  if (game.timestampStart)
    if (game.timestampEnd === null) {
      result += " (Probíhající hra)";
    } else {
      result += " (Ukončená hra)";
    }
  return result;
}

function AutocompleteSearchBar(props: React.HTMLAttributes<HTMLDivElement>) {
  const { data, error } = useGamesList();
  const history = useHistory();
  const [inputValue, setInputValue] = React.useState("");
  if (error) return <HandleError error={error} />;
  let values: GameBriefInfoType[];
  if (data === undefined) {
    values = [];
  } else {
    values = data;
    values.sort((el1, el2) =>
      formatGameString(el1).localeCompare(formatGameString(el2))
    );
  }
  return (
    <Autocomplete
      className={props.className}
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={values}
      inputValue={inputValue}
      onInputChange={(event, newValue) => setInputValue(newValue)}
      onChange={(event, newValue: GameBriefInfoType | string) => {
        // From some reason newValue in onChange can be type of string (error in MUI?)
        if (typeof newValue !== "string") {
          history.push("/game/" + newValue.id);
        } else {
          console.error(`Autocomplete new value is string: ${newValue}`);
        }
      }}
      getOptionLabel={formatGameString}
      renderInput={(params) => (
        <TextField
          {...params}
          className="text-center"
          label="Vyhledat hru"
          margin="none"
          variant="outlined"
          InputProps={{ ...params.InputProps, type: "search" }}
        />
      )}
    />
  );
}

export default AutocompleteSearchBar;
