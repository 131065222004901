export const coordsRegex =
  /^[+-]?\d{1,2}(\.\d+)?([NS])?\s*,\s*[+-]?\d{1,3}(\.\d+)?([EW])?$/;

export function hasCoordsFormat(value: string): boolean {
  return coordsRegex.test(value);
}

export function parseCoords(coordsStr: string): [number, number] {
  if (!hasCoordsFormat(coordsStr))
    throw Error("Souřadnice nemají správný formát");
  const coords = coordsStr.split(",");
  //not working with format where is S or W
  return [parseFloat(coords[0]), parseFloat(coords[1])];
}

export const validateCoords = (input: string) => {
  let coords;
  try {
    coords = parseCoords(input);
  } catch (err) {
    return false;
  }
  return !(
    coords[0] < -90 ||
    coords[0] > 90 ||
    coords[1] < -180 ||
    coords[1] > 180
  );
};
