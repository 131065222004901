import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import PageLogged from "./PageLogged";

export function LoadingData() {
  return <Spinner animation="grow" variant="primary" />;
}

export function LoadingCenter() {
  return (
    <Row className="mt-5">
      <Col xs={12} className="d-flex justify-content-center mt-5">
        <LoadingData />
      </Col>
    </Row>
  );
}

export function LoadingPage() {
  return (
    <PageLogged>
      <LoadingCenter />
    </PageLogged>
  );
}
