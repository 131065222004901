import React, { useContext } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ApiContext, ApiContextType } from "../../contexts/ApiContext";
import TeamMediaImages from "./TeamMediaImages";
import TeamMediaVideos from "./TeamMediaVideos";
import TeamMediaAudios from "./TeamMediaAudios";

const TeamMedia = (props: {
  mediaPhotos: string[];
  mediaAudio: string[];
  mediaVideos: string[];
}) => {
  const { api } = useContext<ApiContextType>(ApiContext);
  return (
    <Tabs defaultActiveKey="photo" id="media-tab" className="my-2">
      <Tab eventKey="photo" title="Foto">
        <TeamMediaImages media={props.mediaPhotos} api={api} />
      </Tab>
      <Tab eventKey="video" title="Video">
        <TeamMediaVideos media={props.mediaVideos} api={api} />
      </Tab>
      <Tab eventKey="audio" title="Audio">
        <TeamMediaAudios media={props.mediaAudio} api={api} />
      </Tab>
    </Tabs>
  );
};

export default TeamMedia;
