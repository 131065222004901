import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import { useTemplatesList } from "../../api/game";
import HandleError from "../HandleError";
import { LoadingCenter } from "../Loading";
import { Redirect } from "react-router-dom";
import GameRow from "../dashboard/GameRow";
import Table from "react-bootstrap/Table";

//todo rethink templates
const Templates = () => {
  const { data: games, error } = useTemplatesList();
  const [loading, setLoading] = useState(false);
  const [openDuplicated, setOpenDuplicated] = useState(false);

  if (error) return <HandleError error={error} />;
  if (!games || loading) return <LoadingCenter />;
  if (openDuplicated) {
    return <Redirect to={`/editor/${openDuplicated}`} />;
  }

  return (
    <>
      <h3 className="mt-5 mb-3">Šablony her</h3>
      <Row className="justify-content-between">
        <Table hover borderless className="dashboard-table">
          <thead>
            <tr>
              <th>Stav hry</th>
              <th>Název hry</th>
              <th>Datum hry</th>
              <th>Jazyk</th>
              <th>Úkolů</th>
              <th>Firma</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {games.map((game, index) => (
              <GameRow
                key={index}
                game={game}
                setLoading={setLoading}
                setOpenDuplicated={setOpenDuplicated}
              />
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
};

export default Templates;
