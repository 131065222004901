import { useGameProgress } from "../../api/game";
import React from "react";
import AnswerDetails from "./AnswerDetails";
import AnswerForm from "./AnswerForm";
import HandleError from "../HandleError";
import { LoadingCenter } from "../Loading";

function Answer(props: { activeGameProgressId: string; gameId: string }) {
  const {
    data: gameProgress,
    error,
    mutate: mutateGameProgress,
  } = useGameProgress(props.activeGameProgressId);
  if (error) return <HandleError error={error} />;
  if (!gameProgress) return <LoadingCenter />;

  return (
    <>
      <AnswerDetails
        activeGameProgressId={props.activeGameProgressId}
        answer={gameProgress.answer}
        questId={gameProgress.questId}
      />
      <AnswerForm
        key={props.activeGameProgressId}
        questId={gameProgress.questId}
        teamId={gameProgress.teamId}
        gameId={props.gameId}
        earnedPoints={gameProgress.points}
        penalizationTime={gameProgress.penalizationTime}
        penalizationHelp={gameProgress.penalizationHelp}
        activeGameProgressId={props.activeGameProgressId}
        mutateGameProgress={mutateGameProgress}
      />
    </>
  );
}

export default Answer;
