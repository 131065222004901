import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { ApiContext } from "../contexts/ApiContext";
import { toast } from "react-toastify";

function CheckAuth() {
  const apiContext = useContext(ApiContext);
  if (!apiContext.api.isSignedIn()) {
    toast.error("Vaše přihlášení vypršelo.", {
      toastId: "error-401",
    });
    apiContext.api.signOut();
    return <Redirect to="/" />;
  }
  return <></>;
}

export default CheckAuth;
