import React, { useState } from "react";
import { useTeamsInGame } from "../../api/game";
import Team from "./Team";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import HandleError from "../HandleError";
import TeamsMap from "../map/TeamsMap";
import { LoadingData } from "../Loading";
import Button from "react-bootstrap/Button";
import MessageModal from "./MessageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useApi } from "../../api/api";
import { sortTeamsByName } from "../../utils";

function TabTeams(props: {
  tabActive: boolean;
  gameId: string;
  questsCount: number;
}) {
  const api = useApi();
  const { data: teams, error } = useTeamsInGame(props.gameId, {
    interval: 10000,
    api: api,
  });

  const [modalShow, setModalShow] = useState(false);
  if (error) return <HandleError error={error} />;
  if (!teams) return <LoadingData />;

  const sortedTeams = sortTeamsByName(teams);

  const teamsJSX = sortedTeams.map((team) => {
    return (
      <ListGroup.Item key={team.id}>
        <Team
          gameId={props.gameId}
          team={team}
          questsCount={props.questsCount}
        />
      </ListGroup.Item>
    );
  });
  const teamsWithCoords = teams.filter((team) => team.coords !== null);

  return (
    <Row>
      <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
        <Row>
          <Button onClick={() => setModalShow(true)}>
            <FontAwesomeIcon icon={faEnvelope} />
            &nbsp;Kontaktovat všechny týmy
          </Button>
        </Row>

        <ListGroup className="scrollable-col" variant="flush">
          {teamsJSX}
        </ListGroup>
      </Col>
      <Col className="p-0" xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
        {teamsWithCoords.length > 0 && props.tabActive ? (
          <TeamsMap teams={teamsWithCoords} />
        ) : (
          <p>Ve hře zatím nejsou žádné týmy.</p>
        )}
      </Col>
      <MessageModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        gameid={props.gameId}
        setModalShow={setModalShow}
      />
    </Row>
  );
}

export default TabTeams;
