import React from "react";
import QRCode from "react-qr-code";
import Modal from "react-bootstrap/Modal";

function QRCodeModal(props: { qr: string; onModalClose: () => void }) {
  return (
    <Modal show={true} centered onHide={props.onModalClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Naskenujte QR kód pomocí aplikace</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <QRCode value={props.qr} />
        <p className="mt-4">{props.qr}</p>
      </Modal.Body>
    </Modal>
  );
}

export default QRCodeModal;
