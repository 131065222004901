import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { inviteUser, useUsers } from "../../api/user";
import { ApiContext } from "../../contexts/ApiContext";

const AddUserForm = () => {
  const { mutate } = useUsers();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const apiContext = useContext(ApiContext);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    inviteUser(apiContext!, email, {
      preApiCallback: () => {},
      thenCallback: () => {
        mutate();
      },
    });
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setShow(!show)}
        className="move-right"
      >
        Pozvat uživatele
      </Button>
      {show && (
        <Form onSubmit={handleSubmit} className="mt-3">
          <Row className="justify-content-center">
            <Col xs={10} sm={8} md={6} lg={5} xl={5} xxl={4} className="mb-3">
              <Form.Group>
                <Form.Control
                  required
                  type="email"
                  placeholder="Email uživatele"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <Col xs={12} className="mb-3">
              <Button variant="primary" type="submit">
                Odeslat pozvánku
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default AddUserForm;
