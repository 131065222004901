import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ProgressBar from "react-bootstrap/ProgressBar";
import { toast } from "react-toastify";
import { useGamePhotos, uploadPhoto } from "../../api/photos";
import { ApiContext } from "../../contexts/ApiContext";
import Images from "./Images";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosProgressEvent } from "axios";

interface IImage {
  preview: string | null;
  raw: Blob | null;
}
const ImageUpload = (props: { handle: any; gameId: string }) => {
  const apiContext = useContext(ApiContext);
  const { mutate, data } = useGamePhotos(props.gameId);
  const [image, setImage] = useState<IImage[]>([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);
  }, [image]);

  const handleChange = (e: any) => {
    const files = e.target.files;
    if (files) {
      Array.from(files).map((file: any) =>
        setImage((image) => [
          ...image,
          {
            preview: URL.createObjectURL(file),
            raw: file,
          },
        ])
      );
    }
  };

  const onUploadProgress = (data: AxiosProgressEvent): void => {
    if (!data.total) return;
    setProgress(Math.round((100 * data.loaded) / data.total));
  };

  const handleUpload = () => {
    if (image.length !== 0 && props.gameId) {
      image.forEach((img) => {
        if (img.raw !== null) {
          uploadPhoto(apiContext, props.gameId, img.raw, onUploadProgress, {
            thenCallback: () => {
              mutate();
              toast.success("Obrázek byl nahrán.");
            },
          });
        }
      });
      setImage([]);
    }
  };

  return (
    <Container className="upload-menu">
      <Col className="text-end my-1">
        <FontAwesomeIcon
          icon={faTimesCircle}
          onClick={props.handle.close}
          size="lg"
        />
      </Col>
      <Row className="justify-content-md-center">
        {image.length !== 0
          ? image.map((img, index) =>
              img.preview ? (
                <img
                  key={index}
                  src={img.preview}
                  alt="preview"
                  className="my-2 w-50"
                />
              ) : null
            )
          : null}
      </Row>
      <ProgressBar now={progress} className="my-2" />
      <Form.Group className="input-group mb-3">
        <Form.Control
          type="file"
          multiple
          accept="image/png, image/gif, image/jpeg"
          size="sm"
          onChange={handleChange}
        />

        <Button size="sm" className="mx-2" onClick={() => handleUpload()}>
          Nahrát
        </Button>
      </Form.Group>
      <Images
        gameId={props.gameId}
        imageUrls={data}
        image={image}
        setImage={setImage}
        handle={props.handle}
      />
    </Container>
  );
};

export default ImageUpload;
