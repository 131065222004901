import React, { useContext, useState } from "react";
import Page from "../components/Page";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { resetPassword } from "../api/password";
import { Link } from "react-router-dom";
import { ApiContext } from "../contexts/ApiContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ResetPasswordRequestPage() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const apiContext = useContext(ApiContext);

  function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    resetPassword(apiContext, email, {
      preApiCallback: () => {
        setError(false);
        setLoading(true);
      },
      thenCallback: (response) => {
        setRequestSuccess(true);
      },
      errorCallback: (error) => {
        setError(true);
      },
      finallyCallback: () => {
        setLoading(false);
      },
    });
  }

  if (requestSuccess && !loading) {
    return (
      <Page>
        <Row className="justify-content-center mt-5 mb-1">
          <Col xs={12} className="text-center">
            <p>Na Váš email byly odeslány pokyny pro obnovení hesla.</p>
            <Link to="/signin">
              <Button variant="primary">Zpět na přihlášení</Button>
            </Link>
          </Col>
        </Row>
      </Page>
    );
  }

  return (
    <Page>
      <Row className="justify-content-center mt-5 mb-1">
        <Col xs={12} sm={8} md={6} lg={5} xl={5} xxl={4}>
          <h1>Zapomenuté heslo</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email" className="mt-4">
              <Form.Label>
                Emailová adresa, ke které jste ztratili heslo:
              </Form.Label>
              <Form.Control
                autoFocus
                required
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                className="mt-2"
              />
            </Form.Group>
            {error && (
              <p className="mt-3 mb-0 text-danger">
                Zadaná emailová adresa neexistuje.
              </p>
            )}
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              className="mt-3"
            >
              Resetovat heslo
            </Button>
          </Form>
        </Col>
      </Row>
    </Page>
  );
}

export default ResetPasswordRequestPage;
