import React, { useState } from "react";
import PageLogged from "../components/PageLogged";
// import ReloadConfirmation from "../components/ReloadConfirmation";
import { useLocation } from "react-router-dom";
import CreateNewQuest from "../components/gameeditor/newquest/CreateNewQuest";
import { RouteComponentProps } from "react-router";
import ReloadConfirmation from "../components/ReloadConfirmation";
import { useGameInfo } from "../api/game";
import HandleError from "../components/HandleError";
import { LoadingCenter } from "../components/Loading";

const QuestEditorPage = (
  props: RouteComponentProps<{ gameId: string; questId: string }>
) => {
  const location: any = useLocation();
  const questType = location.state.questType;

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { data, error } = useGameInfo(props.match.params.gameId);
  if (error) return <HandleError error={error} />;
  if (!data) return <LoadingCenter />;
  const questInfo = data.quests.find(
    ({ id }) => id === props.match.params.questId
  );
  return (
    <PageLogged>
      <ReloadConfirmation when={unsavedChanges} />
      <CreateNewQuest
        gameId={props.match.params.gameId}
        questType={questType}
        finishCoords={data.finishCoords}
        quests={data.quests}
        questInfo={questInfo}
        setUnsavedChanges={setUnsavedChanges}
      />
    </PageLogged>
  );
};

export default QuestEditorPage;
