import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QuestType, QuizAnswerType } from "../../../api/datatypes";

interface IProps {
  answer: string | null;
  setAnswer: Dispatch<React.SetStateAction<string | null>>;
  setInvalidAnswerMessage: Dispatch<SetStateAction<string | null>>;
  setQuestType: Dispatch<React.SetStateAction<QuestType>>;
  questType: QuestType;
}

const QUIZ_DEFAULT_ANSWER = [
  {
    id: 0,
    answer: "",
    correct: true,
  },
  {
    id: 1,
    answer: "",
    correct: false,
  },
];

const QuizAnswer = (props: IProps) => {
  const validateQuizAnswer = useCallback(
    (answers: QuizAnswerType[]) => {
      if (!answers.every((a) => a.answer.trim().length > 0))
        props.setInvalidAnswerMessage("Žádná odpověď nesmí být prázdná.");
      else if (!answers.some((a) => a.correct))
        props.setInvalidAnswerMessage("Není označená správná odpověď.");
      else return props.setInvalidAnswerMessage(null);
    },
    [props]
  );

  const updateAnswer = React.useCallback(
    (answers: { id: number; answer: string; correct: boolean }[]) => {
      props.setAnswer(JSON.stringify(answers));
    },
    [props]
  );

  useEffect(() => {
    if (props.answer === null) updateAnswer(QUIZ_DEFAULT_ANSWER);
  }, [props.answer, updateAnswer]);

  const answerInput: QuizAnswerType[] =
    props.answer !== null ? JSON.parse(props.answer) : QUIZ_DEFAULT_ANSWER;

  useEffect(() => {
    validateQuizAnswer(answerInput);
  }, [validateQuizAnswer, answerInput]);

  const toggleQuiz = () => {
    if (props.questType === QuestType.QUIZ_SINGLE_CHOICE) {
      props.setQuestType(QuestType.QUIZ_MULTI_CHOICE);
    }
    if (props.questType === QuestType.QUIZ_MULTI_CHOICE) {
      const values: any = [...answerInput];
      values.forEach((answer: QuizAnswerType) => {
        if (answer.correct) {
          answer.correct = false;
        }
      });
      values[0].correct = true;
      updateAnswer(values);
      props.setQuestType(QuestType.QUIZ_SINGLE_CHOICE);
    }
  };

  const handleChangeInput = (i: number, e: any) => {
    const values: any = [...answerInput];
    if (props.questType === QuestType.QUIZ_SINGLE_CHOICE) {
      if (e.target.name === "correct") {
        if (values[i].correct === false) {
          values.forEach((answer: QuizAnswerType) => {
            if (answer.correct) {
              answer.correct = false;
            }
          });
          values[i][e.target.name] = e.target.checked;
        }
      } else {
        values[i][e.target.name] = e.target.value;
      }
    } else {
      if (e.target.name === "correct") {
        values[i][e.target.name] = e.target.checked;
      } else {
        values[i][e.target.name] = e.target.value;
      }
    }
    updateAnswer(values);
  };

  const handleAddInput = () => {
    updateAnswer([
      ...answerInput,
      {
        id: answerInput.length,
        answer: "",
        correct: false,
      },
    ]);
  };

  const handleRemoveInput = (i: number) => {
    const values = [...answerInput];
    const filteredValues = values.filter((input, index) => index !== i);
    filteredValues.forEach((answer, i) => {
      answer.id = i;
    });
    updateAnswer([...filteredValues]);
  };

  return (
    <Row>
      <Col xs={12} className="mb-3">
        <Form.Group>
          <Form.Label>Druh kvízu</Form.Label>
          <Form.Check
            type="radio"
            label="Jedna správná odpověd"
            name="formQuizRadios"
            id="formQuizSingle"
            checked={props.questType === QuestType.QUIZ_SINGLE_CHOICE}
            onChange={toggleQuiz}
          />
          <Form.Check
            type="radio"
            label="Více správných odpovědí"
            name="formQuizRadios"
            id="formQuizMulti"
            checked={props.questType === QuestType.QUIZ_MULTI_CHOICE}
            onChange={toggleQuiz}
          />
        </Form.Group>
      </Col>
      <Col xs={12} sm={12} md={10} lg={9} xl={8} xxl={8}>
        <Form.Group controlId="formQuestAnswers">
          <Form.Label>Odpovědi</Form.Label>
          {answerInput.map((input: any, index: any) => (
            <Row key={index}>
              <Col xs="9">
                <Form.Control
                  className="my-2"
                  type="text"
                  name="answer"
                  value={input.answer}
                  onChange={(e) => handleChangeInput(index, e)}
                />
              </Col>
              <Col xs="3" className="d-inline-flex">
                <Form.Check
                  inline
                  name="correct"
                  onChange={(e) => handleChangeInput(index, e)}
                  checked={input.correct === true}
                  className="align-items-center d-inline-flex"
                />
                {answerInput.length > 2 && (
                  <Button
                    onClick={() => handleRemoveInput(index)}
                    variant="link"
                    className="align-items-center d-inline-flex p-0 mt-1"
                  >
                    <FontAwesomeIcon icon={faTimes} className="delete-icon" />
                  </Button>
                )}
              </Col>
            </Row>
          ))}
        </Form.Group>
      </Col>
      <Col xs="12" className="mt-3 mb-2">
        <Button onClick={() => handleAddInput()}>
          <FontAwesomeIcon className="mx-3" icon={faPlus} />
          Přidat odpověd
        </Button>
      </Col>
    </Row>
  );
};

export default QuizAnswer;
