import React, {
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Form from "react-bootstrap/Form";
import {
  HelpType,
  QuestInfoType,
  QuestInfoV2,
  QuestType,
} from "../../../api/datatypes";
import QuizAnswer from "./QuizAnswer";
import OpenAnswer from "./OpenAnswer";
import QuestHelp from "./QuestHelp";
import { createQuest, editQuest, useGameInfo } from "../../../api/game";
import { toast } from "react-toastify";
import { ApiContext } from "../../../contexts/ApiContext";
import { useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MdEditor from "../MdEditor";
import QuestsMap from "../../map/QuestsMap";
import Intro from "../Intro";
import Conclusion from "../Conclusion";
import CoordsForm from "../CoordsForm";
import { parseCoords, validateCoords } from "../../../coordsUtils";

interface IProps {
  gameId: string;
  questType: QuestType;
  finishCoords: [number, number] | null;
  quests: QuestInfoType[];
  questInfo: QuestInfoType | undefined;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormContent = (props: IProps) => {
  const [questType, setQuestType] = useState(props.questType);
  const [questName, setQuestName] = useState(
    props.questInfo ? props.questInfo.name : ""
  );
  const [questTime, setQuestTime] = useState<string | null>(
    props.questInfo
      ? props.questInfo.timeLimit
        ? props.questInfo.timeLimit.toString()
        : null
      : null
  );
  const [questPoints, setQuestPoints] = useState(
    props.questInfo ? props.questInfo.points.toString() : ""
  );
  const [questDesc, setQuestDesc] = useState(
    props.questInfo ? props.questInfo.description : ""
  );
  const [questIntro, setQuestIntro] = useState<string[]>(
    props.questInfo ? props.questInfo.introduction : []
  );
  const [questConclusion, setQuestConclusion] = useState<string[]>(
    props.questInfo ? props.questInfo.conclusion : []
  );
  const [questCoords, setQuestCoords] = useState(
    props.questInfo
      ? `${props.questInfo.coords[0]}N, ${props.questInfo.coords[1]}E`
      : ""
  );
  const [coordsTouched, setCoordsTouched] = useState(false);
  const [questQuestion, setQuestQuestion] = useState(
    props.questInfo ? props.questInfo.question : ""
  );
  const [help, setHelp] = useState<HelpType[]>(
    props.questInfo ? (props.questInfo.help ? props.questInfo.help : []) : []
  );
  const [answer, setAnswer] = useState<string | null>(
    props.questInfo ? props.questInfo.answer : null
  );

  const [nameTouched, setNameTouched] = useState(false);
  const [timeTouched, setTimeTouched] = useState(false);
  const [pointsTouched, setPointsTouched] = useState(false);
  const [questionTouched, setQuestionTouched] = useState(false);
  const [invalidAnswerMessage, setInvalidAnswerMessage] = useState<
    string | null
  >(null);
  const [answerTouched, setAnswerTouched] = useState(false);
  const [helpValid, setHelpValid] = useState(false);
  const [helpTouched, setHelpTouched] = useState(false);
  const [introTouched, setIntroTouched] = useState(false);
  const [introValid, setIntroValid] = useState(false);
  const [conclusionTouched, setConclusionTouched] = useState(false);
  const [conclusionValid, setConclusionValid] = useState(false);

  const isNameValid = questName.trim().length > 0;
  const isTimeValid = questTime ? Number(questTime) > 0 : true;
  const isPointsValid = Number(questPoints) > 0;
  const questCoordsValid = validateCoords(questCoords);
  const isQuestionValid = questQuestion.trim().length > 0;

  const isFormValid =
    isNameValid &&
    isTimeValid &&
    isPointsValid &&
    questCoordsValid &&
    isQuestionValid &&
    invalidAnswerMessage === null &&
    helpValid &&
    introValid &&
    conclusionValid;

  const apiContext = useContext(ApiContext);
  const history = useHistory();
  const { mutate } = useGameInfo(props.gameId);

  useEffect(() => {
    if (
      props.questType !== QuestType.QUIZ_SINGLE_CHOICE &&
      props.questType !== QuestType.QUIZ_MULTI_CHOICE
    ) {
      setInvalidAnswerMessage(null);
    }
  }, [props.questType]);

  const setUnsavedChanges = props.setUnsavedChanges;
  let counter = useRef(0); //ugly hack, but I don't know why useEffect always run after first render.
  useEffect(() => {
    //ugly hack, but I don't know why useEffect always run after first render.
    if (counter.current > 0) {
      setUnsavedChanges(true);
    }
    counter.current += 1;
  }, [
    questType,
    questName,
    questTime,
    questDesc,
    questIntro,
    questConclusion,
    questCoords,
    questQuestion,
    questPoints,
    help,
    answer,
    setUnsavedChanges,
    counter,
  ]);

  const submitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isFormValid) {
      window.scrollTo(0, 0);
      setNameTouched(true);
      setPointsTouched(true);
      setQuestionTouched(true);
      setTimeTouched(true);
      setAnswerTouched(true);
      setHelpTouched(true);
      setIntroTouched(true);
      setConclusionTouched(true);
      setCoordsTouched(true);
      return;
    }
    const data: QuestInfoV2 = {
      name: questName,
      timeLimit: Number(questTime),
      coords: parseCoords(questCoords),
      points: Number(questPoints),
      type: questType,
      description: questDesc,
      introduction: questIntro,
      conclusion: questConclusion,
      question: questQuestion,
      answer: answer,
      help: help,
    };
    props.setUnsavedChanges(false);
    if (!props.questInfo) {
      createQuest(apiContext, props.gameId, data, {
        thenCallback: () => {
          mutate();
          toast.success("Úkol byl vytvořen.");
          history.goBack();
        },
      });
    } else {
      editQuest(apiContext, props.gameId, props.questInfo.id, data, {
        thenCallback: () => {
          mutate();
          toast.success("Úkol byl upraven.");
          history.goBack();
        },
      });
    }
  };

  return (
    <Form noValidate onSubmit={submitHandler} id="questform">
      <Row>
        <Col xs={12} className="mt-2 mb-2">
          <h3>Základní údaje</h3>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="mb-3">
          <Form.Group controlId="formQuestName">
            <Form.Label>Název</Form.Label>
            <Form.Control
              required
              isInvalid={nameTouched && !isNameValid}
              value={questName}
              onChange={(e) => setQuestName(e.target.value)}
              onBlur={() => setNameTouched(true)}
              type="text"
              placeholder="Zadejte název úkolu"
            />
            <Form.Control.Feedback className="mb-3" type="invalid">
              Zadejte prosím název.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} className="mb-3">
          <Form.Group controlId="formQuestTime">
            <Form.Label>
              {props.questType === QuestType.LOCATION
                ? "Úkol bez časového limitu"
                : "Čas na splnění úkolu (sec)"}
            </Form.Label>
            <Form.Control
              type="text"
              required
              isInvalid={timeTouched && !isTimeValid}
              value={questTime ? questTime : ""}
              onChange={(e) => setQuestTime(e.target.value)}
              onBlur={() => setTimeTouched(true)}
              placeholder={
                props.questType === QuestType.LOCATION ? "" : "Zadejte čas"
              }
              disabled={props.questType === QuestType.LOCATION}
            />
            <Form.Control.Feedback className="mb-3" type="invalid">
              Zadejte prosím platný čas na splnění úkolu.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} className="mb-3">
          <Form.Group controlId="formQuestPoints">
            <Form.Label>Body</Form.Label>
            <Form.Control
              required
              isInvalid={pointsTouched && !isPointsValid}
              onChange={(e) => {
                setQuestPoints(
                  Number.isNaN(parseInt(e.target.value))
                    ? ""
                    : parseInt(e.target.value).toString()
                );
              }}
              onBlur={() => setPointsTouched(true)}
              type="text"
              value={questPoints}
              placeholder="Zadejte počet bodů"
            />
            <Form.Control.Feedback className="mb-3" type="invalid">
              Zadejte prosím počet bodů větší než 0.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Group className="mb-3" controlId="formQuestDesc">
            <Form.Label>Popis</Form.Label>
            <MdEditor
              value={questDesc}
              setValue={setQuestDesc}
              gameId={props.gameId}
            />
          </Form.Group>
        </Col>
      </Row>
      <Intro
        intro={questIntro}
        setIntro={setQuestIntro}
        gameId={props.gameId}
        setIntroValid={setIntroValid}
        isQuestCreating={true}
      />
      {!introValid && introTouched && (
        <p className="invalid-input">Vyplňte prosím příběh před úkolem.</p>
      )}
      <CoordsForm
        title={"Umístění úkolu"}
        coords={questCoords}
        setCoords={setQuestCoords}
        coordsTouched={coordsTouched}
        setCoordsTouched={setCoordsTouched}
        required={true}
      />
      <Row>
        <Col xs={12}>
          <QuestsMap
            quests={props.quests}
            gameFinishCoords={
              props.finishCoords ? props.finishCoords : undefined
            }
            questCoords={
              questCoordsValid
                ? {
                    coords: parseCoords(questCoords),
                  }
                : undefined
            }
            adjustBounds={true}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12} className="mt-5 mb-2">
          <h3>Otázka a odpověď</h3>
        </Col>
        <Col xs={12}>
          <Form.Group className="my-3" controlId="formQuestQuestion">
            <Form.Label>Otázka</Form.Label>
            <MdEditor
              value={questQuestion}
              setValue={setQuestQuestion}
              gameId={props.gameId}
            />
            {questionTouched && !isQuestionValid && (
              <p className="invalid-input">Zadejte prosím otázku.</p>
            )}
          </Form.Group>
        </Col>
      </Row>
      {(props.questType === QuestType.QUIZ_SINGLE_CHOICE ||
        props.questType === QuestType.QUIZ_MULTI_CHOICE) && (
        <QuizAnswer
          answer={answer}
          setAnswer={setAnswer}
          setInvalidAnswerMessage={setInvalidAnswerMessage}
          setQuestType={setQuestType}
          questType={questType}
        />
      )}
      {props.questType === QuestType.OPEN && (
        <OpenAnswer
          answer={answer}
          setAnswer={setAnswer}
          setInvalidAnswerMessage={setInvalidAnswerMessage}
        />
      )}
      {invalidAnswerMessage && answerTouched && (
        <p className="invalid-input">{invalidAnswerMessage}</p>
      )}
      <QuestHelp help={help} setHelp={setHelp} setHelpValid={setHelpValid} />
      {!helpValid && helpTouched && (
        <p className="invalid-input">Zadejte prosím nápovědu a penalizaci.</p>
      )}
      <Conclusion
        conclusion={questConclusion}
        setConclusion={setQuestConclusion}
        gameId={props.gameId}
        setConclusionValid={setConclusionValid}
        isQuestCreating={true}
      />
      {!conclusionValid && conclusionTouched && (
        <p className="invalid-input">Vyplňte prosím poučení po úkolu.</p>
      )}
    </Form>
  );
};

export default FormContent;
