import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <Row className="mt-5 mb-3">
      <Col xs="6">
        <h3>Editovat hru</h3>
      </Col>
      <Col xs="6" className="d-flex justify-content-end">
        <Link to="/templates">
          <Button variant="primary">Načíst šablonu</Button>
        </Link>
      </Col>
    </Row>
  );
};

export default Header;
