import axios, { AxiosInstance } from "axios";
import { parseJwt } from "../utils";
import { toast } from "react-toastify";
import { Fetcher } from "swr";

function getCookie(cname: string) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export class Api {
  readonly apiUrl: string;

  constructor() {
    this.apiUrl =
      window.location.hostname === "localhost"
        ? "https://treasurehunt.safio.cz"
        : window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port ? ":" + window.location.port : "");
  }

  getFetcher<T>(): Fetcher<T> {
    return (url: string) =>
      this.getAxios()
        .get<T>(this.apiUrl + "/api" + url)
        .then((res) => res.data)
        .catch((error) => {
          if (error.response.status === 401) {
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            this.signOut();
          }
          throw error.response;
        });
  }

  isSignedIn(): boolean {
    return getCookie("Authorization") !== "";
  }

  signIn(token: string): Api {
    document.cookie = "Authorization=" + token;
    return this;
  }

  signOut(): Api {
    document.cookie =
      "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    return this;
  }

  getAxios(authToken?: string): AxiosInstance {
    const api = axios.create({
      baseURL: this.apiUrl,
      timeout: 0,
      headers: { "Content-Type": "application/json" },
    });
    if (authToken === undefined) {
      api.defaults.headers.common["auth-token"] = getCookie("Authorization");
    } else {
      api.defaults.headers.common["auth-token"] = authToken;
    }
    return api;
  }

  userId(): string | null {
    if (getCookie("Authorization") !== "") {
      const parsedJwt = parseJwt(getCookie("Authorization"));
      return parsedJwt !== null ? parsedJwt["user"] : null;
    } else return null;
  }
}

export function useApi(): Api {
  return new Api();
}

export default Api;
