import React, { useContext } from "react";
import { ApiContext } from "../contexts/ApiContext";
import { Redirect } from "react-router-dom";

function SignOutPage() {
  const apiContext = useContext(ApiContext);
  apiContext.api.signOut();
  return <Redirect to="/" />;
}

export default SignOutPage;
