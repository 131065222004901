import React from "react";
import { Redirect, RouteComponentProps } from "react-router";
import HandleError from "../components/HandleError";
import Page from "../components/Page";
import TeamMedia from "../components/gameresults/TeamMedia";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { LoadingCenter } from "../components/Loading";
import { useReportExport } from "../api/export";
import TeamGameResults from "../components/gameresults/TeamGameResults";

const GameTeamResultsPage = (props: RouteComponentProps<{ id: string }>) => {
  const { data, error } = useReportExport(props.match.params.id);
  if (error) return <HandleError error={error} />;
  if (!data) return <LoadingCenter />;

  if (data.game.timestampEnd === null) {
    return <Redirect to={`/game/${data.game.id}`} />;
  }

  return (
    <Page>
      <Row>
        <Col xs={12} className="mt-4 w-50 mx-auto">
          <Image src="/treasure-hunt-logo.png" fluid />
        </Col>
      </Row>
      <Row className="mt-4 mb-3 text-center">
        <Col xs="12">
          <h1 className="mb-3">Výsledky hry</h1>
          <h2 className="mb-3">{data.game.name}</h2>
          <h3>Tým: {data.team.name}</h3>
        </Col>
      </Row>
      <TeamGameResults teams={data.teams} game={data.game} team={data.team} />
      <Row>
        <Col xs={4}>
          <h3>Media</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <TeamMedia
            mediaPhotos={data.mediaPhotos}
            mediaVideos={data.mediaVideos}
            mediaAudio={data.mediaAudio}
          />
        </Col>
      </Row>
    </Page>
  );
};

export default GameTeamResultsPage;
