import React from "react";
import { QuestInfoType } from "../../api/datatypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faClock } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  questTypeToColor,
  questTypeToIcon,
  secondsToTimeStringLiterals,
} from "../../utils";
import CircleIcon from "../CircleIcon";
import Badge from "react-bootstrap/Badge";
import { QuestType } from "../../api/datatypes";

function Quest(props: { type: QuestType; quest: QuestInfoType }) {
  const questTypeIcon: JSX.Element = questTypeToIcon(props.quest.type);
  const questTypeColor = questTypeToColor(props.quest.type);
  return (
    <Row className="text-left align-items-center">
      <Col xs={3}>
        <CircleIcon color={questTypeColor} content={questTypeIcon} />
      </Col>
      <Col xs={9}>
        {props.quest.position + 1} - {props.quest.name}
        <Row>
          <Col>
            <Badge pill>
              <FontAwesomeIcon icon={faStar} />
              &nbsp;{props.quest.points}
            </Badge>
          </Col>
          {props.quest.timeLimit && props.quest.timeLimit > 0 ? (
            <Col>
              <Badge pill bg="success">
                <FontAwesomeIcon icon={faClock} />
                &nbsp;{secondsToTimeStringLiterals(props.quest.timeLimit)}
              </Badge>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default Quest;
