import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Page from "../components/Page";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { signIn as SigninFunction } from "../api/signIn";
import { ApiContext } from "../contexts/ApiContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function SignInPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const apiContext = useContext(ApiContext);

  function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    SigninFunction(apiContext, email, password, {
      preApiCallback: () => {
        setError(false);
        setLoading(true);
      },
      thenCallback: (response) => {
        apiContext.signIn(response.data.token);
      },
      errorCallback: () => {
        setError(true);
      },
      finallyCallback: () => {
        setLoading(false);
      },
    });
  }

  if (apiContext.api.isSignedIn() && !loading) {
    // Use window.location.href to force reload the whole app
    // on each login instead of using Redirect. This is because
    // the SWR needs to load the changed API token.
    window.location.href = "/dashboard";
  }

  return (
    <Page>
      <Row className="justify-content-center mt-5 mb-1">
        <Col
          xs={12}
          sm={8}
          md={6}
          lg={5}
          xl={5}
          xxl={4}
          className="text-center"
        >
          <Image src="/treasure-hunt-logo.png" fluid />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} sm={8} md={6} lg={5} xl={5} xxl={4}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus
                required
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="password" className="mt-3">
              <Form.Label>Heslo</Form.Label>
              <Form.Control
                required
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Form.Group>
            {error && <p>Přihlášení se nezdařilo, zkuste to prosím znovu.</p>}
            <Row className="mt-4">
              <Col className="m-0 p-0">
                <Link to="/signin/reset-password">
                  <Button variant="link" className="text-muted">
                    Nepamatujete si heslo?
                  </Button>
                </Link>
              </Col>
              <Col xs="2">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={loading}
                  className="float-right"
                >
                  Přihlásit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Page>
  );
}

export default SignInPage;
