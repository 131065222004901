import React, { useState, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { changePassword } from "../../api/password";
import { ApiContext } from "../../contexts/ApiContext";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const UserChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [apiSuccess, setApiSuccess] = useState(false);
  const apiContext = useContext(ApiContext);

  const handleChange = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    changePassword(apiContext, oldPassword, newPassword, {
      thenCallback: () => {
        toast.success("Vaše heslo bylo úspěšně změneno.");
        setApiSuccess(true);
      },
    });
  };

  const validatePassword = () => {
    return newPassword === confirmPassword && newPassword.length >= 8;
  };

  if (apiSuccess)
    return (
      <Row className="justify-content-center mt-5 mb-1 text-center">
        <Col xs={12} sm={8} md={6} lg={5} xl={5} xxl={4}>
          <p>Vaše heslo bylo úspěšně změněno.</p>
          <Link to="/">Zpět na dashboard</Link>
        </Col>
      </Row>
    );

  return (
    <Row className="justify-content-center mt-5 mb-1">
      <Col xs={12} sm={8} md={6} lg={5} xl={5} xxl={4}>
        <h1>Změnit heslo</h1>
        <Form onSubmit={handleChange}>
          <Form.Group className="mt-3" controlId="oldPassword">
            <Form.Label>Staré heslo</Form.Label>
            <Form.Control
              required
              autoFocus
              type="password"
              value={oldPassword}
              onChange={(event) => setOldPassword(event.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3" controlId="newPassword">
            <Form.Label>Nové heslo</Form.Label>
            <Form.Control
              required
              type="password"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3" controlId="confirmPassword">
            <Form.Label>Nové heslo znovu</Form.Label>
            <Form.Control
              required
              type="password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
            />
          </Form.Group>
          <Button
            className="mt-3"
            variant="primary"
            type="submit"
            disabled={!validatePassword()}
          >
            Změnit heslo
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default UserChangePassword;
