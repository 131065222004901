import React from "react";
import UserCard from "./UserCard";
import Row from "react-bootstrap/Row";
import { useUsers } from "../../api/user";
import HandleError from "../HandleError";
import AddUserForm from "./AddUserForm";
import { LoadingCenter } from "../Loading";

function Users() {
  const { data: users, error } = useUsers();

  if (error) return <HandleError error={error} />;
  if (!users) return <LoadingCenter />;

  return (
    <>
      <h3 className="mt-5 mb-3">Uživatelé</h3>
      <AddUserForm />
      <Row>
        {users.map((user) => (
          <UserCard key={user.id} user={user} />
        ))}
      </Row>
    </>
  );
}

export default Users;
