import React, { useState } from "react";
import {
  TeamContactType,
  TeamInfoExportType,
  TeamRatingType,
} from "../../api/datatypes";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import RatingWithPopover from "./RatingWithPopover";
import Modal from "react-bootstrap/Modal";

const TableGameResultHeader = (props: { isExporting: boolean }) => {
  return (
    <thead>
      <tr>
        <th>Umístění</th>
        <th>Tým</th>
        <th>Čas hry</th>
        <th>Splněno</th>
        <th>Body</th>
        {!props.isExporting && (
          <>
            <th>Kontakt</th>
            <th> </th> {/*Header for contact button*/}
            <th>Hodnocení</th>
            <th>Detaily</th>
          </>
        )}
      </tr>
    </thead>
  );
};

function ContactsModal(props: {
  contacts: TeamContactType;
  onModalClose: () => void;
}) {
  return (
    <Modal show={true} centered onHide={props.onModalClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Kontakt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>SOS jméno:</h6>
        <p>{props.contacts.sosName}</p>
        <h6>SOS telefonní číslo:</h6>
        <p>{props.contacts.sosPhone}</p>
        <h6>Email:</h6>
        {props.contacts.emails.map((email) => (
          <p>{email}</p>
        ))}
      </Modal.Body>
    </Modal>
  );
}

const TableGameResultRow = (props: {
  gameId: string;
  team: TeamInfoExportType;
  gameTime: string;
  rating: TeamRatingType;
  ratingNote: string;
  questsCount: number;
  totalPoints: number;
  isExporting: boolean;
  contacts: TeamContactType;
  position: number;
  isRowHighlighted: boolean;
}) => {
  const [showContacts, setShowContacts] = useState(false);

  return (
    <>
      <tbody>
        <tr className={props.isRowHighlighted ? "table-primary" : ""}>
          <td>{props.position}.</td>
          <td>{props.team.name}</td>
          <td>{props.gameTime}</td>
          <td>
            {props.team.completedQuests}/{props.questsCount}
          </td>
          <td>
            {props.team.points}/{props.totalPoints}
          </td>
          {!props.isExporting && (
            <>
              <td style={{ width: "1px", whiteSpace: "nowrap" }}>
                {props.contacts.emails[0]}
              </td>
              <td>
                <Button
                  className="ms-3"
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => setShowContacts(true)}
                >
                  Zobrazit vše
                </Button>
              </td>
              <td>
                <RatingWithPopover
                  ratings={[props.rating]}
                  note={props.ratingNote}
                  placement="top"
                />
              </td>
              <td>
                <Link to={`/results/team/${props.team.id}`}>
                  <Button>Export</Button>
                </Link>
              </td>
            </>
          )}
        </tr>
      </tbody>
      {showContacts && (
        <ContactsModal
          contacts={props.contacts}
          onModalClose={() => setShowContacts(false)}
        />
      )}
    </>
  );
};

interface IProps {
  gameId: string;
  teams: TeamInfoExportType[];
  gameTimeElapsed: string;
  gameTimeLimit: string;
  questsCount: number;
  pointsSum: number;
  isExporting: boolean;
  exportingTeamName?: string;
}

const TableGameResults = (props: IProps) => {
  return (
    <Table borderless responsive="sm">
      <TableGameResultHeader isExporting={props.isExporting} />
      {props.teams.map((team, index) => (
        <TableGameResultRow
          isExporting={props.isExporting}
          gameId={props.gameId}
          key={team.name}
          team={team}
          gameTime={`${props.gameTimeElapsed}/${props.gameTimeLimit}`}
          rating={team.rating}
          ratingNote={team.ratingNote}
          questsCount={props.questsCount}
          totalPoints={props.pointsSum}
          contacts={team.teamContact}
          position={index + 1}
          isRowHighlighted={team.name === props.exportingTeamName}
        />
      ))}
    </Table>
  );
};

export default TableGameResults;
