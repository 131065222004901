import React from "react";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="text-center footer mt-auto py-3 text-dark">
      <hr className="hr-short" />
      <div className="container text-small text-muted">
        © 2021 {year > 2021 ? "- " + year : ""}{" "}
        <a href="https://hotrock.cz/">HOTROCK s.r.o.</a>,
        <br />
        Vytvořeno <a href="https://www.safio.cz/">Safio, s.r.o.</a>.
      </div>
    </footer>
  );
}

export default Footer;
