import React, { useContext, useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { UserType } from "../../api/datatypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import ActionConfirmationModal from "../ActionConfirmationModal";
import { deleteUser, useUsers } from "../../api/user";
import { ApiContext } from "../../contexts/ApiContext";
import { toast } from "react-toastify";

function ContextMenu(props: { userId: string }): React.ReactElement {
  const { mutate: mutateUsers } = useUsers();
  const apiContext = useContext(ApiContext);
  const [show, setShow] = useState(false);

  function handleDeleteUser() {
    deleteUser(apiContext, props.userId, {
      thenCallback: () => {
        mutateUsers();
        toast.success(`Uživatel byl smazán.`);
      },
      errorCallback: () => {
        toast.success(`Nepodařilo se smazat uživatele.`);
      },
    });
  }

  return (
    <>
      <Dropdown className="position-absolute top-0 start-0">
        <Dropdown.Toggle variant="basic">
          <FontAwesomeIcon icon={faBars} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setShow(true)}>
            Smazat uživatele
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <ActionConfirmationModal
        show={show}
        title={"Smazat uživatele"}
        body={`Opravdu si přejete smazat tohoto uživatele?`}
        confirmation={"Smazat uživatele"}
        onClickNo={() => setShow(false)}
        onClickYes={() => {
          handleDeleteUser();
          setShow(false);
        }}
        confirmButtonVariant={"danger"}
      />
    </>
  );
}

function UserCard(props: { user: UserType }) {
  return (
    <Col xs={12} sm={6} md={4} lg={4} xl={3} xxl={3} className="p-3">
      <Card className="h-100">
        <ContextMenu userId={props.user.id} />
        <Card.Body className="text-center align-items-center d-inline-flex flex-column justify-content-center">
          <Row className="mb-3">
            <Col xs={12}>
              <FontAwesomeIcon icon={faUser} size="5x" className="user-icon" />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card.Text>{props.user.name}</Card.Text>
            </Col>
            <Col xs={12}>
              <Card.Text>{props.user.email}</Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default UserCard;
