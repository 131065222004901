import React, { Dispatch, SetStateAction, useMemo } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import { useTeamAnswers } from "../../api/game";
import {
  TeamInfoType,
  GameProgressInfoType,
  QuestInfoType,
} from "../../api/datatypes";
import AnswerMenuItem from "./AnswerMenuItem";
import Accordion from "react-bootstrap/Accordion";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HandleError from "../HandleError";
import CircleIcon from "../CircleIcon";
import { Colors, normalizeString } from "../../utils";
import { LoadingCenter } from "../Loading";
import Badge from "react-bootstrap/Badge";

interface TeamAnswersSWRProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  team: TeamInfoType;
  quests: Array<QuestInfoType>;
  setActiveGameProgressId: Dispatch<SetStateAction<string | null>>;
  filterText: string;
}

interface TeamAnswersProps extends TeamAnswersSWRProps {
  answers: GameProgressInfoType[];
}

function compareGameProgress(
  a: { quest: QuestInfoType; gameProgress: GameProgressInfoType },
  b: { quest: QuestInfoType; gameProgress: GameProgressInfoType }
): number {
  return a.quest.position - b.quest.position;
}

function TeamAnswersSWR(props: TeamAnswersSWRProps) {
  const { data: answers, error } = useTeamAnswers(props.team.id);
  if (error) return <HandleError error={error} />;
  if (!answers) return <LoadingCenter />;

  return <TeamAnswers {...props} answers={answers} />;
}

function TeamAnswers(props: TeamAnswersProps) {
  const questsToEval = props.answers
    .filter((gameProgress) => gameProgress.timestampEnd !== null)
    .map((gameProgress) => {
      const quest = props.quests.find(
        (quest) => quest.id === gameProgress.questId
      );
      if (quest !== undefined) {
        return { quest, gameProgress };
      } else {
        throw TypeError(
          "Quest description can not be assigned to game progress"
        );
      }
    });

  const { filterText, setActiveGameProgressId } = props;

  const questMenuItems = useMemo(
    () =>
      questsToEval
        .sort(compareGameProgress)
        .filter((questToEval) =>
          normalizeString(questToEval.quest.name).includes(
            normalizeString(filterText)
          )
        )
        .map((questToEval) => {
          return (
            <ListGroup.Item
              key={questToEval.gameProgress.questId}
              className="menu-item-answer"
              onClick={() =>
                setActiveGameProgressId(questToEval.gameProgress.gameProgressId)
              }
            >
              <AnswerMenuItem questToEval={questToEval} />
            </ListGroup.Item>
          );
        }),
    [filterText, setActiveGameProgressId, questsToEval]
  );

  return (
    <Accordion.Item eventKey={props.team.id}>
      <Accordion.Header>
        <Col xs={3}>
          <CircleIcon color={Colors.RED} content={props.team.name[0]} />
        </Col>
        <Col xs="9">
          <Row>
            <Col xs="12">{props.team.name}</Col>
          </Row>
          <Row>
            <Col xs="12" className="mt-2">
              <Badge pill bg="success">
                <FontAwesomeIcon icon={faCheckCircle} />
                &nbsp;{props.team.completedQuests}/{props.quests.length}
              </Badge>
            </Col>
          </Row>
        </Col>
      </Accordion.Header>
      <Accordion.Body>
        <ListGroup variant="flush">{questMenuItems}</ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default TeamAnswersSWR;
