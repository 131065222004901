import React from "react";
import { QuestType } from "../../../api/datatypes";
import { questTypeToHeader } from "../../../utils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

interface IProps {
  questType: QuestType;
  onBackButtonClick: () => void;
  isQuestEditing: boolean;
}

const QuestHeader = (props: IProps) => {
  return (
    <Row className="mt-5 mb-3">
      <Col xs="12">
        <Button onClick={props.onBackButtonClick} variant="link">
          <FontAwesomeIcon size="2x" icon={faAngleLeft} />
        </Button>
        <h3 className="d-inline-block">
          {props.isQuestEditing ? "Upravit úkol" : "Vytvořit úkol"} -{" "}
          {questTypeToHeader(props.questType)}
        </h3>
      </Col>
    </Row>
  );
};

export default QuestHeader;
