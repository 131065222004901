import { createContext, ReactNode, useReducer } from "react";
import Api from "../api/api";
import ApiContextReducer from "../reducers/ApiContextReducer";

export interface ApiContextType {
  api: Api;
  signIn: (token: string) => void;
  signOut: () => void;
}

const initialApiContext = {
  api: new Api(),
  signIn: () => {},
  signOut: () => {},
};

export const ApiContext = createContext<ApiContextType>(initialApiContext);

export const ApiContextProvider = ({ children }: { children: ReactNode }) => {
  const [, dispatch] = useReducer(ApiContextReducer, initialApiContext);

  const signIn = (token: string): void => {
    dispatch({
      type: "SIGN_IN",
      payload: token,
    });
  };
  const signOut = () => {
    dispatch({
      type: "SIGN_OUT",
    });
  };

  return (
    <ApiContext.Provider
      value={{ api: new Api(), signIn: signIn, signOut: signOut }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContextProvider;
