import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TeamRatingType } from "../../api/datatypes";
import Rating from "@mui/material/Rating";
import Button from "react-bootstrap/Button";
import { average } from "../../arrayUtils";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Placement } from "react-bootstrap/types";

const RatingWithPopover = (props: {
  ratings: (TeamRatingType | null)[];
  note?: string;
  placement?: string;
}) => {
  const prt = props.ratings.filter((r): r is TeamRatingType => r !== null);
  if (prt.length === 0) {
    return (
      <Rating name="half-rating-read" value={0} precision={0.5} readOnly />
    );
  }
  const averageRatings = prt.map((r) => average(Object.values(r)));
  const averageOverallRating = average(averageRatings);

  const rDifficulty = average(prt.map((r) => r["difficulty"]));
  const rFun = average(prt.map((r) => r["fun"]));
  const rInformativeness = average(prt.map((r) => r["informativeness"]));
  const rPlaces = average(prt.map((r) => r["places"]));
  const rRecommendation = average(prt.map((r) => r["recommendation"]));
  const rVariety = average(prt.map((r) => r["variety"]));

  const placement: string = props.placement || "bottom";

  const unifiedRating = (title: string, value: number) => {
    return (
      <Row>
        <Col xs={12}>
          <strong>{title}</strong>
        </Col>
        <Col xs={12}>
          <Rating
            name="half-rating-read"
            value={value}
            precision={0.5}
            readOnly
          />
        </Col>
      </Row>
    );
  };

  const popoverNote = (
    <Row>
      <Col xs={12}>
        <strong>Slovní hodnocení</strong>
      </Col>
      <Col xs={12}>{props.note}</Col>
    </Row>
  );

  const popover = (
    <Popover>
      <Popover.Body>
        {unifiedRating("Zábavnost hry", rFun)}
        {unifiedRating("Obtížnost logických úkolů", rDifficulty)}
        {unifiedRating("Rozmanitost úkolů", rVariety)}
        {unifiedRating("Zajímavost navštívených míst", rPlaces)}
        {unifiedRating("Poučnost, získání nových dovedností", rInformativeness)}
        {unifiedRating("Doporučili byste hru ostatním", rRecommendation)}
        {props.note && popoverNote}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement={placement as Placement}
      overlay={popover}
    >
      <Button variant="link" className="m-0 p-0">
        <Rating
          name="half-rating-read"
          value={averageOverallRating}
          precision={0.5}
          readOnly
        />
      </Button>
    </OverlayTrigger>
  );
};

export default RatingWithPopover;
