import React, { useState, useContext, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Dropdown from "react-bootstrap/Dropdown";
import QuestListItem from "./QuestListItem";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QuestInfoType, QuestType } from "../../api/datatypes";
import { sortQuestsByPosition } from "../../arrayUtils";
import { useHistory } from "react-router-dom";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { editQuestPosition, useGameInfo } from "../../api/game";
import { ApiContext } from "../../contexts/ApiContext";

interface IProps {
  gameId: string;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  quests: QuestInfoType[];
}

const QuestList = (props: IProps) => {
  useEffect(() => {
    setSortedQuests(props.quests);
  }, [props.quests]);
  const apiContext = useContext(ApiContext);
  const { mutate } = useGameInfo(props.gameId);
  const [sortedQuests, setSortedQuests] = useState(
    sortQuestsByPosition(props.quests)
  );

  const history = useHistory();

  const handleEditQuestPosition = (questId: string, newPosition: number) => {
    editQuestPosition(apiContext, props.gameId, questId, newPosition, {
      thenCallback: () => {
        mutate();
      },
    });
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const items = Array.from(sortedQuests);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSortedQuests(items);
    handleEditQuestPosition(result.draggableId, result.destination.index);
  };

  const handleLink = (questType: QuestType) => {
    window.scrollTo(0, 0);
    history.push({
      pathname: `/editor/${props.gameId}/quest`,
      state: { questType: questType },
    });
  };

  return (
    <>
      <Dropdown className="mb-3">
        <Dropdown.Toggle id="dropdown-quest">
          <FontAwesomeIcon className="mx-1" icon={faPlus} /> Přidat úkol
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => handleLink(QuestType.QUIZ_SINGLE_CHOICE)}
          >
            Kvíz
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleLink(QuestType.PHOTO)}>
            Foto
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleLink(QuestType.VIDEO)}>
            Video
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleLink(QuestType.AUDIO)}>
            Audio
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleLink(QuestType.OPEN)}>
            Otevřená odpověd
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleLink(QuestType.LOCATION)}>
            Dojít na místo
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="quests">
          {(provided) => (
            <ListGroup
              {...provided.droppableProps}
              className="scrollable-col"
              variant="flush"
              ref={provided.innerRef}
            >
              {sortedQuests.map((quest, index) => {
                return (
                  <Draggable
                    key={quest.id}
                    draggableId={quest.id}
                    index={index}
                  >
                    {(provided) => (
                      <QuestListItem
                        provided={provided}
                        quests={props.quests}
                        gameId={props.gameId}
                        questType={quest.type}
                        questInfo={quest}
                        position={index}
                      />
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ListGroup>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default QuestList;
