import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { GameBriefInfoType } from "../../api/datatypes";
import { Link } from "react-router-dom";
import {
  deleteGame,
  duplicateGame,
  useGamesList,
  useTemplatesList,
} from "../../api/game";
import { ApiContext } from "../../contexts/ApiContext";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import ActionConfirmationModal from "../ActionConfirmationModal";
import GameStatus from "./GameStatus";
import OverallRating from "./OverallRating";
import { dateToString } from "../../utils";

function ContextMenu(props: {
  gameId: string;
  isTemplate: boolean;
  isRunningOrEnded: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDuplicated: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement {
  const { mutate: mutateGames } = useGamesList();
  const { mutate: mutateTemplates } = useTemplatesList();
  const apiContext = useContext(ApiContext);
  const [show, setShow] = useState(false);

  function handleDeleteGame() {
    deleteGame(apiContext, props.gameId, {
      thenCallback: () => {
        props.isTemplate ? mutateTemplates() : mutateGames();
        toast.success(`${props.isTemplate ? "Šablona" : "Hra"} byla smazána.`);
      },
    });
  }

  function handleDuplicateGame() {
    props.setLoading(true);
    duplicateGame(apiContext, props.gameId, {
      thenCallback: (response) => {
        props.isTemplate ? mutateTemplates() : mutateGames();
        props.setLoading(false);
        toast.success(
          `${props.isTemplate ? "Šablona" : "Hra"} byla duplikována.`
        );
        props.setOpenDuplicated(response.data);
      },
    });
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle className="w-100 h-100" variant="basic">
          <FontAwesomeIcon className="fa-clickable" icon={faBars} size="lg" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {!props.isRunningOrEnded && (
            <Dropdown.Item as={Link} to={`/editor/${props.gameId}`}>
              Otevřít v editoru
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={handleDuplicateGame}>
            {props.isTemplate ? "Duplikovat šablonu" : "Duplikovat hru"}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShow(true)}>
            {props.isTemplate ? "Smazat šablonu" : "Smazat hru"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <ActionConfirmationModal
        show={show}
        title={props.isTemplate ? "Smazat šablonu" : "Smazat hru"}
        body={`Opravdu si přejete smazat tuto ${
          props.isTemplate ? "šablonu" : "hru"
        }?`}
        confirmation={props.isTemplate ? "Smazat šablonu" : "Smazat hru"}
        onClickNo={() => setShow(false)}
        onClickYes={() => {
          handleDeleteGame();
          setShow(false);
        }}
        confirmButtonVariant={"danger"}
      />
    </>
  );
}

interface GameRowProps {
  game: GameBriefInfoType;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDuplicated: React.Dispatch<React.SetStateAction<boolean>>;
}

const GameRow = ({ game, setLoading, setOpenDuplicated }: GameRowProps) => {
  const history = useHistory();
  const isGameRunning =
    game.timestampStart !== null && game.timestampEnd === null;
  const isGameEnded =
    game.timestampStart !== null && game.timestampEnd !== null;
  const gameLastEditDate = new Date(game.lastEditTimestamp * 1000);
  const gameLastEditStr = dateToString(gameLastEditDate);
  const gamePlannedStartDate = game.timestampGamePlannedStart
    ? new Date(game.timestampGamePlannedStart * 1000)
    : null;
  const gamePlannedStartStr = gamePlannedStartDate
    ? dateToString(gamePlannedStartDate, false)
    : "--. --. ----";
  const gamePlayedDate = game.timestampStart
    ? new Date(game.timestampStart * 1000)
    : null;
  const gamePlayedStr = gamePlayedDate
    ? dateToString(gamePlayedDate, false)
    : "--. --. ----";

  let badge = isGameRunning ? (
    <GameStatus
      background={"badge-green"}
      timeLimit={game.timeLimit}
      timestampStart={game.timestampStart}
    />
  ) : isGameEnded ? (
    <GameStatus background={"badge-red"}>Ukončená</GameStatus>
  ) : (
    <GameStatus background={"badge-yellow"}>Plánovaná</GameStatus>
  );

  function handleRightClick(
    e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>
  ) {
    e.preventDefault();
    window.open(`/${game.isTemplate ? "editor" : "game"}/${game.id}`, "_blank");
  }

  function handleLeftClick(
    e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>
  ) {
    e.preventDefault();
    history.push(`/${game.isTemplate ? "editor" : "game"}/${game.id}`);
  }

  return (
    <tr className="dashboard-table-row cursor-pointer">
      <td
        className="text-center"
        onClick={(e) => handleLeftClick(e)}
        onContextMenu={(e) => handleRightClick(e)}
      >
        {badge}
      </td>
      <td
        onClick={(e) => handleLeftClick(e)}
        onContextMenu={(e) => handleRightClick(e)}
      >
        <div className="fs-5">{game.name}</div>
        {isGameEnded ? (
          <OverallRating gameId={game.id} />
        ) : (
          <div className="small">
            Upraveno: <b>{gameLastEditStr}</b> od <b>{game.lastEditName}</b>
          </div>
        )}
      </td>
      <td
        onClick={(e) => handleLeftClick(e)}
        onContextMenu={(e) => handleRightClick(e)}
      >
        {isGameEnded || isGameRunning ? gamePlayedStr : gamePlannedStartStr}
      </td>
      <td
        onClick={(e) => handleLeftClick(e)}
        onContextMenu={(e) => handleRightClick(e)}
      >
        {game.language ? game.language : "-"}
      </td>
      <td
        onClick={(e) => handleLeftClick(e)}
        onContextMenu={(e) => handleRightClick(e)}
      >
        {game.questsCount}
      </td>
      <td
        onClick={(e) => handleLeftClick(e)}
        onContextMenu={(e) => handleRightClick(e)}
      >
        <div>{game.company ? game.company : "-"}</div>
      </td>
      <td>
        <ContextMenu
          gameId={game.id}
          isTemplate={game.isTemplate}
          isRunningOrEnded={isGameRunning || isGameEnded}
          setLoading={setLoading}
          setOpenDuplicated={setOpenDuplicated}
        />
      </td>
    </tr>
  );
};

export default GameRow;
