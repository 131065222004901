import React from "react";
import { useGameProgressMedia } from "../../api/photos";
import HandleError from "../HandleError";
import { LoadingCenter } from "../Loading";
import { QuestType } from "../../api/datatypes";
import Image from "react-bootstrap/Image";

const AnswerMedia = (props: {
  activeGameProgressId: string;
  questType: QuestType;
}) => {
  const { data, error } = useGameProgressMedia(
    props.activeGameProgressId,
    props.questType.toLocaleLowerCase()
  );
  if (error) return <HandleError error={error} />;
  if (!data) return <LoadingCenter />;

  let media: JSX.Element[] | undefined = undefined;
  if (props.questType === QuestType.PHOTO) {
    media = data.map((image, index) => {
      return <Image key={index} src={`${image}-512`} alt="answer image" />;
    });
  }

  if (props.questType === QuestType.AUDIO) {
    media = data.map((audio, index) => {
      return (
        <audio controls key={index}>
          <source src={`${audio}`} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      );
    });
  }

  if (props.questType === QuestType.VIDEO) {
    media = data.map((video, index) => {
      return (
        <video className="video-player" controls key={index}>
          <source src={`${video}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    });
  }

  return (
    <>
      <h5>Odpověď</h5>
      {media ? media : <p>Nevyplněno.</p>}
    </>
  );
};

export default AnswerMedia;
