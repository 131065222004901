import React, { useState, useContext, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { EditGameInfo, GameInfo, QuestInfoType } from "../../api/datatypes";
import { ApiContext } from "../../contexts/ApiContext";
import { useGamesList, editGame, useGameInfo } from "../../api/game";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Switch from "../Switch";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cs } from "date-fns/locale";
import Intro from "./Intro";
import Conclusion from "./Conclusion";
import Quests from "./Quests";
import CoordsForm from "./CoordsForm";
import { parseCoords, validateCoords } from "../../coordsUtils";

registerLocale("cs", cs);

interface IProps {
  gameId: string;
  game: GameInfo;
  quests: QuestInfoType[];
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateGameForm = (props: IProps) => {
  const [gameName, setGameName] = useState(props.game.name);
  const [gameTime, setGameTime] = useState(
    props.game.timeLimit === 0 ? "" : props.game.timeLimit.toString()
  );
  const [linearGame, setLinearGame] = useState(props.game.linearGame);
  const [gameIntro, setGameIntro] = useState<string[]>(props.game.introduction);
  const [gameOutro, setGameOutro] = useState<string[]>(props.game.conclusion);
  const [gamePlannedStart, setGamePlannedStart] = useState<Date | null>(
    props.game.timestampGamePlannedStart
      ? new Date(props.game.timestampGamePlannedStart * 1000)
      : null
  );
  const [gameLanguage, setGameLanguage] = useState(props.game.language);
  const [gameCompany, setGameCompany] = useState(props.game.company);
  const [introValid, setIntroValid] = useState(false);
  const [conclusionTouched, setConclusionTouched] = useState(false);
  const [conclusionValid, setConclusionValid] = useState(false);
  const [nameTouched, setNameTouched] = useState(false);
  const [timeTouched, setTimeTouched] = useState(false);
  const [introTouched, setIntroTouched] = useState(false);
  const [finishCoords, setFinishCoords] = useState(
    props.game.finishCoords
      ? `${props.game.finishCoords[0]}N, ${props.game.finishCoords[1]}E`
      : ""
  );
  const [finishCoordsTouched, setFinishCoordsTouched] = useState(false);

  const finishCoordsValid = finishCoords === "" || validateCoords(finishCoords);
  const isNameValid = gameName.trim().length > 0;
  const isTimeValid = Number(gameTime) > 0;
  const isFormValid =
    isNameValid &&
    isTimeValid &&
    introValid &&
    conclusionValid &&
    finishCoordsValid;

  const { mutate } = useGamesList();
  const { mutate: mutateGame } = useGameInfo(props.gameId);
  const apiContext = useContext(ApiContext);

  let history = useHistory();

  const setGameTimeMinutesStr = (time: string) => {
    if (parseFloat(time) > 0) {
      setGameTime((parseFloat(time) * 60).toString());
    } else {
      setGameTime("");
    }
  };

  const setUnsavedChanges = props.setUnsavedChanges;
  let counter = useRef(0); //ugly hack, but I don't know why useEffect always run after first render.

  useEffect(() => {
    if (counter.current > 0) {
      //ugly hack, but I don't know why useEffect always run after first render.
      setUnsavedChanges(true);
    }
    counter.current += 1;
  }, [
    gameName,
    gameTime,
    gameIntro,
    gameOutro,
    setUnsavedChanges,
    counter,
    finishCoords,
  ]);

  const handleEditGame = () => {
    const gameInformation: EditGameInfo = {
      name: gameName,
      introduction: gameIntro,
      conclusion: gameOutro,
      timeLimit: parseFloat(gameTime) > 0 ? parseFloat(gameTime) : 0,
      isTemplate: props.game.isTemplate,
      linearGame: linearGame,
      timestampGamePlannedStart: gamePlannedStart
        ? gamePlannedStart.getTime() / 1000
        : null,
      language: gameLanguage,
      company: gameCompany,
      finishCoords: finishCoords === "" ? null : parseCoords(finishCoords),
    };
    editGame(apiContext, props.gameId, gameInformation, {
      thenCallback: () => {
        mutate();
        mutateGame();
        toast.success("Hra byla upravena.");
        history.push("/dashboard");
      },
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!isFormValid) {
      setNameTouched(true);
      setTimeTouched(true);
      setConclusionTouched(true);
      setIntroTouched(true);
      setFinishCoordsTouched(true);
      window.scrollTo(0, 0);
      return;
    }
    props.setUnsavedChanges(false);
    handleEditGame();
  };
  return (
    <Form id="gameform" noValidate onSubmit={handleSubmit}>
      <Row className="d-flex">
        <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={3}>
          <Form.Group controlId="formGameName">
            <Form.Label>Název</Form.Label>
            <Form.Control
              required
              isInvalid={!isNameValid && nameTouched}
              onBlur={() => {
                setNameTouched(true);
              }}
              value={gameName}
              onChange={(e) => setGameName(e.target.value)}
              type="text"
              placeholder="Zadejte název hry"
            />
            <Form.Control.Feedback className="mb-3" type="invalid">
              Zadejte prosím název.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={3}>
          <Form.Group className="mb-3" controlId="formGameTime">
            <Form.Label>Celkový čas hry (minuty)</Form.Label>
            <Form.Control
              required
              isInvalid={!isTimeValid && timeTouched}
              type="text"
              value={
                Number.isNaN(parseFloat(gameTime) / 60)
                  ? ""
                  : parseFloat(gameTime) / 60
              }
              onChange={(e) => setGameTimeMinutesStr(e.target.value)}
              onBlur={() => {
                setTimeTouched(true);
              }}
              placeholder="Zadejte celkový čas hry"
            />
            <Form.Control.Feedback className="mb-3" type="invalid">
              Zadejte prosím platné číslo.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={3}>
          <Form.Group controlId="formGameDate">
            <Form.Label>Plánované datum hry (nepovinný)</Form.Label>
            <DatePicker
              wrapperClassName="w-100"
              className="form-control"
              selected={gamePlannedStart}
              onChange={(gamePlannedStart: Date | null) =>
                setGamePlannedStart(
                  gamePlannedStart
                    ? new Date(gamePlannedStart.setHours(12))
                    : null
                )
              }
              dateFormat="dd. MM. yyyy"
              minDate={new Date()}
              placeholderText="Zadejte datum hry"
              locale="cs"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={3}>
          <Form.Group controlId="formGameCompany">
            <Form.Label>Firma (nepovinný)</Form.Label>
            <Form.Control
              value={gameCompany || ""}
              onChange={(e) => setGameCompany(e.target.value)}
              type="text"
              placeholder="Zadejte název firmy"
            />
          </Form.Group>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={3}>
          <Form.Group controlId="formGameLanguage">
            <Form.Label>Jazyk hry (nepovinný)</Form.Label>
            <Form.Select
              className="form-control"
              value={gameLanguage || ""}
              onChange={(e) => setGameLanguage(e.target.value)}
              placeholder="Zadejte jazyk hry"
            >
              <option value="">Neuvedeno</option>
              <option value="CZE">CZE</option>
              <option value="ENG">ENG</option>
              <option value="GER">GER</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={3} className="mb-2">
          <label className="mb-2">Lineární pořadí úkolů</label>
          <Switch
            checked={linearGame}
            onChange={() => setLinearGame(!linearGame)}
          />
        </Col>
      </Row>
      <Intro
        intro={gameIntro}
        setIntro={setGameIntro}
        gameId={props.gameId}
        setIntroValid={setIntroValid}
        isQuestCreating={false}
      />
      {!introValid && introTouched && (
        <p className="invalid-input">Zadejte prosím úvod do hry.</p>
      )}
      <Quests
        gameId={props.gameId}
        gameFinishCoords={
          finishCoords.length > 0 && finishCoordsValid
            ? parseCoords(finishCoords)
            : undefined
        }
        quests={props.quests}
        setUnsavedChanges={props.setUnsavedChanges}
      />
      <CoordsForm
        title={"Souřadnice cíle"}
        coords={finishCoords}
        setCoords={setFinishCoords}
        coordsTouched={finishCoordsTouched}
        setCoordsTouched={setFinishCoordsTouched}
        required={false}
      />
      <Conclusion
        conclusion={gameOutro}
        setConclusion={setGameOutro}
        gameId={props.gameId}
        setConclusionValid={setConclusionValid}
        isQuestCreating={false}
      />
      {!conclusionValid && conclusionTouched && (
        <p className="invalid-input">Zadejte prosím závěr hry.</p>
      )}
    </Form>
  );
};

export default CreateGameForm;
