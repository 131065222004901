import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import { useGamesList } from "../../api/game";
import HandleError from "../HandleError";
import { LoadingCenter } from "../Loading";
import { Redirect } from "react-router-dom";
import CreateGameModal from "../gameeditor/CreateGameModal";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import GameRow from "./GameRow";
import Col from "react-bootstrap/Col";

function Dashboard() {
  const { data: games, error } = useGamesList();
  const [loading, setLoading] = useState(false);
  const [openDuplicated, setOpenDuplicated] = useState(false);
  const [showModal, setShowModal] = useState(false);

  if (error) return <HandleError error={error} />;
  if (!games || loading) return <LoadingCenter />;

  if (openDuplicated) {
    return <Redirect to={`/editor/${openDuplicated}`} />;
  }

  const liveGames = games
    .filter(
      (game) => game.timestampStart !== null && game.timestampEnd === null
    )
    .sort((a, b) => b.timestampStart! - a.timestampStart!);

  const preparedGames = games
    .filter(
      (game) => game.timestampStart === null && game.timestampEnd === null
    )
    .sort((a, b) => b.lastEditTimestamp - a.lastEditTimestamp);

  const finishedGames = games
    .filter(
      (game) => game.timestampStart !== null && game.timestampEnd !== null
    )
    .sort((a, b) => b.timestampEnd! - a.timestampEnd!);

  const unfinishedGames = [...liveGames, ...preparedGames];

  return (
    <>
      <Row>
        <Col className="mt-5 mb-3" xs="6">
          <h3>Naplánované hry</h3>
        </Col>
        <Col xs="6" className="d-flex justify-content-end mt-5 mb-3">
          <Button onClick={() => setShowModal(!showModal)} variant="primary">
            Vytvořit hru
          </Button>
        </Col>
      </Row>
      <Row>
        <Table hover borderless className="dashboard-table">
          <thead>
            <tr>
              <th>Stav hry</th>
              <th>Název hry</th>
              <th>Datum hry</th>
              <th>Jazyk</th>
              <th>Úkolů</th>
              <th>Firma</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {unfinishedGames.map((game, index) => (
              <GameRow
                key={index}
                game={game}
                setLoading={setLoading}
                setOpenDuplicated={setOpenDuplicated}
              />
            ))}
          </tbody>
        </Table>
      </Row>

      <h3 className="mt-5 mb-3">Proběhlé hry</h3>
      <Row className="justify-content-between">
        <Table hover borderless className="dashboard-table">
          <thead>
            <tr>
              <th>Stav hry</th>
              <th>Název hry</th>
              <th>Datum hry</th>
              <th>Jazyk</th>
              <th>Úkolů</th>
              <th>Firma</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {finishedGames.map((game, index) => (
              <GameRow
                key={index}
                game={game}
                setLoading={setLoading}
                setOpenDuplicated={setOpenDuplicated}
              />
            ))}
          </tbody>
        </Table>
      </Row>
      <CreateGameModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}

export default Dashboard;
