import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTeamsInGame } from "../../api/game";
import Accordion from "react-bootstrap/Accordion";
import Answer from "./Answer";
import HandleError from "../HandleError";
import { LoadingCenter } from "../Loading";
import { useApi } from "../../api/api";
import { sortTeamsByName } from "../../utils";
import { QuestInfoType } from "../../api/datatypes";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TeamAnswersSWR from "./TeamAnswers";

function Evaluation(props: { gameId: string; quests: Array<QuestInfoType> }) {
  const [activeGameProgressId, setActiveGameProgressId] = useState<
    string | null
  >(null);
  const [filterText, setFilterText] = useState("");
  const api = useApi();

  const { data, error } = useTeamsInGame(props.gameId, {
    interval: 10000,
    api: api,
  });
  if (error) return <HandleError error={error} />;
  if (!data) return <LoadingCenter />;

  const sortedTeams = sortTeamsByName(data);

  const teams = sortedTeams.map((team) => {
    return (
      <TeamAnswersSWR
        key={team.id}
        team={team}
        quests={props.quests}
        setActiveGameProgressId={setActiveGameProgressId}
        filterText={filterText}
      />
    );
  });

  return (
    <Row>
      <Col
        className="scrollable-answer"
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        xxl={4}
      >
        <Accordion flush>
          <InputGroup className="my-2">
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <FormControl
              type="text"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              placeholder="Vyhledat úkol"
              aria-label="Vyhledat úkol"
            />
          </InputGroup>
          {teams}
        </Accordion>
      </Col>
      <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
        {activeGameProgressId ? (
          <Answer
            key={activeGameProgressId}
            activeGameProgressId={activeGameProgressId}
            gameId={props.gameId}
          />
        ) : (
          <p className="text-center mt-5">Vyberte úkol k ohodnocení.</p>
        )}
      </Col>
    </Row>
  );
}

export default Evaluation;
