import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import { SWRConfig } from "swr";
import ApiContextProvider from "./contexts/ApiContext";
import Api from "./api/api";
import { ToastContainer, toast } from "react-toastify";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import ErrorPage from "./pages/ErrorPage";

function App() {
  const api: Api = new Api();
  return (
    //  The provider can be used to log any information using hook useTrackMetric or use TrackEvent
    //  https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => <ErrorPage />}
        appInsights={reactPlugin}
      >
        <div className="App">
          <ToastContainer
            autoClose={3000}
            hideProgressBar={true}
            limit={5}
            position="bottom-right"
          />
          <ApiContextProvider>
            <SWRConfig
              value={{
                fetcher: (url) =>
                  api
                    .getAxios()
                    .get<any>(api.apiUrl + "/api" + url)
                    .then((res) => res.data)
                    .catch((error) => {
                      if (error.response.status === 401) {
                        toast.error("Vaše přihlášení vypršelo.", {
                          toastId: "error-401",
                        });
                        api.signOut();
                      }
                      throw error.response;
                    }),
                revalidateOnMount: true,
                revalidateOnFocus: true,
                refreshInterval: 0,
                shouldRetryOnError: true,
                dedupingInterval: 2000,
                focusThrottleInterval: 5000,
                loadingTimeout: 3000,
                errorRetryInterval: 5000,
                errorRetryCount: 3,
                // onLoadingSlow, onSuccess, onError, onErrorRetry -- interesting
              }}
            >
              <Router
                getUserConfirmation={(message, callback) => {
                  // this is the default behavior
                  const allowTransition = window.confirm(message);
                  callback(allowTransition);
                }}
              >
                <Routes />
              </Router>
            </SWRConfig>
          </ApiContextProvider>
        </div>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}

export default App;
