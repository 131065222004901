import { toast } from "react-toastify";
import { ApiCallbacks, defaultCallbacks, noop } from "./utils";
import { ApiContextType } from "../contexts/ApiContext";
import { Token } from "./datatypes";

export function signIn(
  apiContext: ApiContextType,
  email: string,
  password: string,
  callbacks: ApiCallbacks<Token> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .post<Token>("/api/v1/web/login", {
      email: email,
      password: password,
    })
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Nesprávné uživatelské jméno nebo heslo.");
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}
