interface GameBriefInfoType {
  id: string;
  name: string;
  timeLimit: number;
  totalPoints: number;
  questsCount: number;
  isTemplate: boolean;
  timestampStart: number | null;
  timestampEnd: number | null;
  lastEditTimestamp: number;
  lastEditName: string;
  timestampGamePlannedStart: number | null;
  language: string | null;
  company: string | null;
}

interface HelpType {
  id: number;
  text: string;
  penalization: number;
}

export const enum QuestType {
  QUIZ_SINGLE_CHOICE = "QUIZ_SINGLE_CHOICE",
  QUIZ_MULTI_CHOICE = "QUIZ_MULTI_CHOICE",
  PHOTO = "PHOTO",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  OPEN = "OPEN",
  LOCATION = "LOCATION",
  EVENT = "EVENT",
}
interface Token {
  token: string;
}

// For setting quests when getting game data on GameEditorPage
interface QuestInfoType extends QuestInfoV2 {
  id: string;
  position: number;
}

// For creating quest in gameEditor - position is added automatically by server
interface QuestInfoV2 {
  name: string;
  timeLimit: number | null;
  coords: [number, number];
  points: number;
  type: QuestType;
  description: string;
  introduction: Array<string>;
  conclusion: Array<string>;
  question: string;
  answer: string | null;
  help: Array<HelpType> | null;
}

interface CreateGameInfoV2 {
  name: string;
  isTemplate: boolean;
}

interface GameInfo {
  id: string;
  name: string;
  introduction: Array<string>;
  conclusion: Array<string>;
  timeLimit: number;
  isTemplate: boolean;
  totalPoints: number;
  timestampStart: number | null;
  timestampEnd: number | null;
  quests: Array<QuestInfoType>;
  linearGame: boolean;
  company: string | null;
  language: string | null;
  timestampGamePlannedStart: number | null;
  lastEditTimestamp: number;
  lastEditName: string;
  finishCoords: [number, number] | null;
}
interface EditGameInfo {
  name: string;
  introduction: Array<string>;
  conclusion: Array<string>;
  timeLimit: number;
  isTemplate: boolean;
  linearGame: boolean;
  timestampGamePlannedStart: number | null;
  language: string | null;
  company: string | null;
  finishCoords: [number, number] | null;
}

interface TeamContactType {
  emails: Array<string>;
  sosName: string;
  sosPhone: string;
}

interface TeamInfoType {
  id: string;
  name: string;
  coords: [number, number];
  completedQuests: number;
  points: number;
  teamContact: TeamContactType;
}

interface TeamRatingType {
  difficulty: number;
  fun: number;
  informativeness: number;
  places: number;
  recommendation: number;
  variety: number;
}

interface TeamInfoExportType {
  id?: string;
  name: string;
  completedQuests: number;
  points: number;
  rating: TeamRatingType;
  ratingNote: string;
  teamContact: TeamContactType;
}

interface ReportExport {
  game: GameBriefInfoType;
  team: TeamInfoExportType;
  teams: TeamInfoExportType[];
  completedQuests: GameProgressInfoType[];
  mediaPhotos: string[];
  mediaVideos: string[];
  mediaAudio: string[];
}

interface GameProgressInfoType {
  answer: string | null;
  points: number | null;
  penalizationHelp: number | null;
  penalizationTime: number | null;
  gameProgressId: string;
  questId: string;
  teamId: string;
  timestampStart: number;
  timestampEnd: number | null;
}

interface UserType {
  id: string;
  name: string | null;
  email: string;
}

interface QuizAnswerType {
  id: number;
  answer: string;
  correct: boolean;
}

export type {
  GameBriefInfoType,
  GameInfo,
  HelpType,
  QuestInfoType,
  CreateGameInfoV2,
  QuestInfoV2,
  EditGameInfo,
  TeamContactType,
  TeamInfoType,
  TeamInfoExportType,
  TeamRatingType,
  GameProgressInfoType,
  ReportExport,
  UserType,
  QuizAnswerType,
  Token,
};
