import React from "react";
import { Link, Redirect } from "react-router-dom";
import Page from "../components/Page";

function HomePage() {
  return (
    <>
      <Redirect to="/signin" />;
      <Page>
        This is a homepage.
        <br />
        <Link to="/signin">Sign In</Link>
      </Page>
    </>
  );
}

export default HomePage;
