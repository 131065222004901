import React, { Dispatch, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import MdEditor from "./MdEditor";
import { areStringsInArrayNonEmpty } from "../../arrayUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  conclusion: string[];
  setConclusion: React.Dispatch<React.SetStateAction<string[]>>;
  gameId: string;
  setConclusionValid: Dispatch<React.SetStateAction<boolean>>;
  isQuestCreating: boolean;
}

const Conclusion = (props: IProps) => {
  useEffect(() => {
    props.setConclusionValid(areStringsInArrayNonEmpty(props.conclusion));
  }, [props]);

  const handleAddConclusion = () => {
    props.setConclusion([...props.conclusion, ""]);
  };

  const handleRemoveConclusion = (i: number) => {
    const values = [...props.conclusion];
    const filteredValues = values.filter((input, index) => index !== i);

    props.setConclusion([...filteredValues]);
  };

  const handleChangeConclusion = (i: number, value: string) => {
    const values: any = [...props.conclusion];
    values[i] = value;
    props.setConclusion(values);
  };

  return (
    <Row>
      {props.isQuestCreating ? (
        <Col xs={12} className="mt-5 mb-2">
          <h3>Příběh a poučení po úkolu</h3>
        </Col>
      ) : (
        <h4>Závěr hry</h4>
      )}
      {props.conclusion.map((conclusion, index) => (
        <>
          <Col xs="12" className="mb-3" key={index}>
            <Form.Group>
              <Form.Label>
                {props.isQuestCreating
                  ? `Poučení po úkolu č. ${index + 1}`
                  : `Závěr hry č. ${index + 1}`}
                <Button
                  onClick={() => handleRemoveConclusion(index)}
                  variant="link"
                >
                  <FontAwesomeIcon icon={faTimes} className="delete-icon" />
                </Button>
              </Form.Label>
              <MdEditor
                value={conclusion}
                setValue={(value: string) =>
                  handleChangeConclusion(index, value)
                }
                gameId={props.gameId}
              />
            </Form.Group>
          </Col>
        </>
      ))}
      <Col xs="12" className="mb-3">
        <Button onClick={() => handleAddConclusion()}>
          {props.isQuestCreating
            ? "Přidat poučení po úkolu"
            : "Přidat závěr hry"}
        </Button>
      </Col>
    </Row>
  );
};

export default Conclusion;
