import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextAreaTextApi } from "@uiw/react-md-editor";
import Form from "react-bootstrap/Form";
import { ApiContext, ApiContextType } from "../../contexts/ApiContext";

const VideoUpload = (props: { handle: any; gameId: string }) => {
  const { api: apiContext } = useContext<ApiContextType>(ApiContext);
  const [url, setUrl] = useState<string>("");

  const modifyText = (api: TextAreaTextApi, url: string) => {
    const replacedText = `\n[![Video](${apiContext.apiUrl}/video-256.jpg)](${url})\n`;
    api.replaceSelection(replacedText);
    props.handle.close();
  };

  return (
    <Container className="upload-menu">
      <Col className="text-end my-1">
        <FontAwesomeIcon
          icon={faTimesCircle}
          onClick={props.handle.close}
          size="lg"
        />
      </Col>
      <Form.Group className="mb-3">
        <Form.Label>Odkaz na video</Form.Label>
        <Form.Control
          type="email"
          placeholder="Například: https://www.youtube.com/watch?v=ZK_3H0Zeh2Q"
          onChange={(event) => setUrl(event.target.value)}
        />
      </Form.Group>
      <Button onClick={() => modifyText(props.handle.textApi, url)}>
        Vložit
      </Button>
    </Container>
  );
};

export default VideoUpload;
