import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, Popup, useMap } from "react-leaflet";
import * as L from "leaflet";
import { getPosition, mapBounds } from "../../utils";
import { LoadingData } from "../Loading";
import { activeMarker, MarkerItemsListType } from "./mapDatatypes";

interface IProps<MarkerItemType> {
  finishCoords?: [number, number];
  activeMarker?: activeMarker;
  markerItems?: MarkerItemsListType<MarkerItemType>;
}

const redIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const greyIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

function MapWithMarkers<MarkerItemType>(
  props: IProps<MarkerItemType>
): JSX.Element {
  const [position, setPosition] = useState<[number, number]>([0, 0]);
  const [positionLoading, setPositionLoading] = useState(true);

  const markerItemsWithBounds = !!(
    props.markerItems &&
    props.markerItems.adjustBounds &&
    props.markerItems.list.length > 0
  );

  useEffect(() => {
    getPosition()
      .then((position: GeolocationPosition) => {
        setPosition([position.coords.latitude, position.coords.longitude]);
        setPositionLoading(false);
      })
      .catch((err) => {
        setPosition([50.0755, 14.4378]);
        setPositionLoading(false);
        console.log(err);
      });
  }, [setPosition, setPositionLoading]);

  const AdjustBounds = (props: {
    bounds: [[number, number], [number, number]];
  }) => {
    const map = useMap();
    map.fitBounds(props.bounds);
    return null;
  };

  const SelectedMarker = () => {
    if (props.activeMarker) {
      return (
        <Marker
          icon={redIcon}
          key={`${props.activeMarker.coords[0]}_${props.activeMarker.coords[1]}`}
          position={[
            props.activeMarker.coords[0],
            props.activeMarker.coords[1],
          ]}
          interactive={false}
        />
      );
    } else {
      return null;
    }
  };

  const FinishMarker = () => {
    if (props.finishCoords) {
      return (
        <Marker
          icon={greyIcon}
          key={`${props.finishCoords[0]}_${props.finishCoords[1]}`}
          position={[props.finishCoords[0], props.finishCoords[1]]}
        >
          <Popup>Cíl hry</Popup>
        </Marker>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {positionLoading ? (
        <LoadingData />
      ) : (
        <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
          {props.markerItems &&
            props.markerItems.list.map((item, index) => (
              <Marker
                position={props.markerItems!.itemToCoords(item)}
                key={index}
              >
                {props.markerItems!.itemToPopupText && (
                  <Popup>{props.markerItems!.itemToPopupText(item)}</Popup>
                )}
              </Marker>
            ))}
          {markerItemsWithBounds &&
            props.finishCoords &&
            props.activeMarker && (
              <AdjustBounds
                bounds={mapBounds([
                  ...props.markerItems!.list.map((item) =>
                    props.markerItems!.itemToCoords(item)
                  ),
                  props.finishCoords,
                  [props.activeMarker.coords[0], props.activeMarker.coords[1]],
                ])}
              />
            )}
          {markerItemsWithBounds &&
            props.finishCoords &&
            !props.activeMarker && (
              <AdjustBounds
                bounds={mapBounds([
                  ...props.markerItems!.list.map((item) =>
                    props.markerItems!.itemToCoords(item)
                  ),
                  props.finishCoords,
                ])}
              />
            )}
          {markerItemsWithBounds &&
            !props.finishCoords &&
            props.activeMarker && (
              <AdjustBounds
                bounds={mapBounds([
                  ...props.markerItems!.list.map((item) =>
                    props.markerItems!.itemToCoords(item)
                  ),
                  [props.activeMarker.coords[0], props.activeMarker.coords[1]],
                ])}
              />
            )}
          {markerItemsWithBounds &&
            !props.finishCoords &&
            !props.activeMarker && (
              <AdjustBounds
                bounds={mapBounds(
                  props.markerItems!.list.map((item) =>
                    props.markerItems!.itemToCoords(item)
                  )
                )}
              />
            )}
          <SelectedMarker />
          <FinishMarker />
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
      )}
    </>
  );
}
export default MapWithMarkers;
