import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import AutocompleteSearchBar from "./AutocompleteSearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faUserCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

function Menu() {
  return (
    <div className="mb-4">
      <Navbar>
        <Navbar.Brand href="/dashboard">
          <img
            src="/treasure-hunt-logo.png"
            alt="TreasureHunt"
            className="app-logo"
            loading="lazy"
          />
        </Navbar.Brand>
        <AutocompleteSearchBar className="d-none d-sm-block w-100 search-bar" />
        <Nav>
          <Nav.Link href="/templates">
            <FontAwesomeIcon icon={faFile} size="2x" className="nav-icon" />
          </Nav.Link>
          <Nav.Link href="/users">
            <FontAwesomeIcon icon={faUsers} size="2x" className="nav-icon" />
          </Nav.Link>
          <NavDropdown
            title={
              <FontAwesomeIcon
                icon={faUserCircle}
                size="2x"
                className="nav-icon"
              />
            }
            align="end"
          >
            <NavDropdown.Item href="/user/password-change">
              Změnit heslo
            </NavDropdown.Item>
            <NavDropdown.Item href="/signout">Odhlásit</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar>
      <AutocompleteSearchBar className="d-block d-sm-none w-100 search-bar" />
    </div>
  );
}

export default Menu;
