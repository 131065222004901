import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { TextAreaTextApi } from "@uiw/react-md-editor";
import { ApiContext, ApiContextType } from "../../contexts/ApiContext";

interface IImage {
  preview: string | null;
  raw: Blob | null;
}

interface IProps {
  gameId: string;
  imageUrls?: string[];
  image: IImage[];
  handle: any;
  setImage: React.Dispatch<React.SetStateAction<IImage[]>>;
}

const Images = (props: IProps) => {
  const { api: apiContext } = useContext<ApiContextType>(ApiContext);

  const modifyText = (api: TextAreaTextApi, image: string) => {
    let replacedText = `\n![](${apiContext.apiUrl}${image}?size=1024)\n`;
    api.replaceSelection(replacedText);
    props.handle.close();
  };

  return (
    <Row>
      {props.imageUrls && props.imageUrls.length > 0 ? (
        props.imageUrls
          .slice(0)
          .reverse()
          .map((image, index) => (
            <Image
              className="col-2 col-sm-4 col-md-3 col-lg-2 my-2"
              onClick={() => modifyText(props.handle.textApi, image)}
              key={index}
              src={`${apiContext.apiUrl}${image}?size=256&crop=1`}
              alt="img"
            />
          ))
      ) : (
        <p className="text-center">Žádné obrázky.</p>
      )}
    </Row>
  );
};

export default Images;
