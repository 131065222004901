import React from "react";
import { QuizAnswerType } from "../../api/datatypes";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

const AnswerQuiz = (props: { answer: string; quizAnswers: string | null }) => {
  if (props.quizAnswers === null) return null;

  const parsedAnswer: number[] | number = JSON.parse(props.answer);
  const teamAnswers: number[] = Array.isArray(parsedAnswer)
    ? parsedAnswer
    : [parsedAnswer];
  const answers = JSON.parse(props.quizAnswers).map(
    (answer: QuizAnswerType) => {
      const icon = teamAnswers.includes(answer.id) ? (
        answer.correct ? (
          <FontAwesomeIcon className="right-answer" icon={faCheckCircle} />
        ) : (
          <FontAwesomeIcon className="wrong-answer" icon={faTimesCircle} />
        )
      ) : null;
      const className = answer.correct
        ? "right-answer fw-bolder"
        : teamAnswers.includes(answer.id)
        ? "wrong-answer fw-bolder"
        : "";
      return (
        <Row key={answer.id}>
          <Col className="d-flex gap-2">
            {icon}
            <p className={` ${className} ${icon === null ? "mx-4" : ""}`}>
              {answer.answer}
            </p>
          </Col>
        </Row>
      );
    }
  );

  return (
    <>
      <h5>Odpověď</h5>
      {answers}
    </>
  );
};

export default AnswerQuiz;
