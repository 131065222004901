import React, { useContext, useState } from "react";
import GameDetails from "../components/gameview/GameDetails";
import { Redirect, RouteComponentProps } from "react-router";
import { stopGame, useGameInfo } from "../api/game";
import HandleError from "../components/HandleError";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import TabTeams from "../components/gameview/TabTeams";
import TabQuests from "../components/gameview/TabQuests";
import Evaluation from "../components/evaluation/Evaluation";
import { useHistory } from "react-router-dom";
import { ApiContext } from "../contexts/ApiContext";
import { toast } from "react-toastify";
import PageLogged from "../components/PageLogged";
import { LoadingPage } from "../components/Loading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActionConfirmationModal from "../components/ActionConfirmationModal";

function GamePage(props: RouteComponentProps<{ id: string }>) {
  const history = useHistory();
  const apiContext = useContext(ApiContext);
  const [tabKey, setTabKey] = useState<string>("quests");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const {
    mutate: mutateGameInfo,
    data: game,
    error,
  } = useGameInfo(props.match.params.id);
  if (error) return <HandleError error={error} />;
  if (!game) return <LoadingPage />;

  function handleBtnEndClick() {
    stopGame(apiContext, game!.id, {
      preApiCallback: () => {
        setLoading(true);
      },
      thenCallback: () => {
        mutateGameInfo();
        toast.success("Hra byla ukončena.");
      },
      finallyCallback: () => {
        setLoading(false);
      },
    });
  }

  if (game.timestampEnd && !loading)
    return <Redirect to={`/results/game/${game.id}`} />;

  return (
    <PageLogged>
      <GameDetails
        id={game.id}
        name={game.name}
        language={game.language}
        company={game.company}
        timestampGamePlannedStart={game.timestampGamePlannedStart}
        intro={game.introduction[0]} // TODO
        timestampStart={game.timestampStart}
        timeLimit={game.timeLimit}
        linearGame={game.linearGame}
      />
      <Row className="mt-5">
        <Col xs={12}>
          <Tabs
            id="game-tab"
            activeKey={tabKey}
            onSelect={(key) => {
              if (key) setTabKey(key);
            }}
          >
            <Tab eventKey="quests" title="Úkoly">
              <TabQuests
                tabActive={tabKey === "quests"}
                finishCoords={game.finishCoords}
                quests={game.quests}
              />
            </Tab>
            <Tab eventKey="teams" title="Týmy" disabled={!game.timestampStart}>
              <TabTeams
                tabActive={tabKey === "teams"}
                gameId={game.id}
                questsCount={game.quests.length}
              />
            </Tab>
            <Tab
              eventKey="points"
              title="Hodnocení"
              disabled={!game.timestampStart}
            >
              <Evaluation gameId={game.id} quests={game.quests} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Row className="mt-5 text-center">
        <Col xs={12}>
          {game.timestampStart ? (
            <Button
              onClick={() => setShow(true)}
              disabled={loading}
              variant="danger"
            >
              Předčasně ukončit hru
            </Button>
          ) : (
            <Button
              onClick={() => {
                history.push(`/editor/${game!.id}`);
              }}
              variant="primary"
            >
              Editovat hru
            </Button>
          )}
        </Col>
      </Row>
      <ActionConfirmationModal
        show={show}
        title={"Předčasně ukončit hru"}
        body={"Opravdu si přejete předčasně ukončit probíhající hru?"}
        confirmation={"Ukončit probíhající hru"}
        onClickNo={() => setShow(false)}
        onClickYes={() => {
          handleBtnEndClick();
          setShow(false);
        }}
        confirmButtonVariant={"danger"}
      />
    </PageLogged>
  );
}

export default GamePage;
