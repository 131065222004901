import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { calculateTimeLeft, dateToString } from "../../utils";
import QRCode from "react-qr-code";
import Button from "react-bootstrap/Button";
import QRCodeModal from "./QRCodeModal";
import MDEditor from "@uiw/react-md-editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Switch from "../Switch";

interface gameDetailsProps {
  id: string;
  name: string;
  company: string | null;
  language: string | null;
  linearGame: boolean;
  timestampGamePlannedStart: number | null;
  intro: string;
  timestampStart: number | null;
  timeLimit: number;
}

function GameDetails(props: gameDetailsProps) {
  const [showQR, setShowQR] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeLeft(props.timeLimit, props.timestampStart)
  );
  const gamePlannedStartDate = props.timestampGamePlannedStart
    ? new Date(props.timestampGamePlannedStart * 1000)
    : null;
  const gamePlannedStartStr = gamePlannedStartDate
    ? dateToString(gamePlannedStartDate, false)
    : "--. --. ----";

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(
        calculateTimeLeft(props.timeLimit, props.timestampStart)
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [timeRemaining, props.timeLimit, props.timestampStart]);

  return (
    <>
      <Row className="mt-5">
        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="mb-3">
          <h3>Název</h3>
          {props.name}
        </Col>
        <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} className="mb-3">
          <h3>Celkový čas hry</h3>
          {timeRemaining}
        </Col>
        <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} className="mb-3">
          <h3>Plánované datum hry</h3>
          {gamePlannedStartStr}
        </Col>
        <Col xs={6} sm={6} md={2} lg={2} xl={2} xxl={2} className="mb-3">
          <Button
            variant="link"
            onClick={() => setShowQR(true)}
            className="float-right"
          >
            <QRCode value={props.id} size={128} />
          </Button>
          {showQR && (
            <QRCodeModal qr={props.id} onModalClose={() => setShowQR(false)} />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="mb-3">
          <h3>Firma</h3>
          {props.company || "-"}
        </Col>
        <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} className="mb-3">
          <h3>Jazyk hry</h3>
          {props.language || "-"}
        </Col>
        <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} className="mb-3">
          <h3>Linearní hra</h3>
          <Switch checked={props.linearGame} />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs="12">
          <h3>Popis</h3>
          {showDesc && <MDEditor.Markdown source={props.intro} />}
        </Col>
      </Row>
      <Button
        className="mt-2"
        size="sm"
        variant="outline-primary"
        onClick={() => setShowDesc(!showDesc)}
      >
        <FontAwesomeIcon icon={showDesc ? faAngleUp : faAngleDown} />
        {showDesc ? " Skrýt popis" : " Zobrazit popis "}
      </Button>
    </>
  );
}

export default GameDetails;
