import React from "react";
import Templates from "../components/templates/Templates";
import PageLogged from "../components/PageLogged";

function TemplatesPage() {
  return (
    <PageLogged>
      <Templates />
    </PageLogged>
  );
}

export default TemplatesPage;
