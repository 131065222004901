import React, { useContext, useState } from "react";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import {
  questTypeToColor,
  questTypeToIcon,
  secondsToTimeStringLiterals,
} from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faStar,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { deleteQuest, useGameInfo } from "../../api/game";
import { QuestInfoType, QuestType } from "../../api/datatypes";
import ActionConfirmationModal from "../ActionConfirmationModal";
import CircleIcon from "../CircleIcon";
import { toast } from "react-toastify";
import { ApiContext } from "../../contexts/ApiContext";
import { useHistory } from "react-router-dom";
import { DraggableProvided } from "react-beautiful-dnd";

const QuestListItem = (props: {
  gameId: string;
  quests: QuestInfoType[];
  questType: QuestType;
  position: number;
  questInfo: QuestInfoType;
  provided: DraggableProvided;
}) => {
  const history = useHistory();
  const apiContext = useContext(ApiContext);
  const { mutate } = useGameInfo(props.gameId);

  const [show, setShow] = useState(false);

  const editHandler = () => {
    window.scrollTo(0, 0);
    history.push({
      pathname: `/editor/${props.gameId}/quest/${props.questInfo.id}`,
      state: {
        questType: props.questInfo.type,
      },
    });
  };

  const deleteQuestHandler = () => {
    deleteQuest(apiContext, props.gameId, props.questInfo.id, {
      thenCallback: () => {
        mutate();
        toast.success("Úkol byl vymazán.");
        setShow(false);
      },
    });
  };

  const questTypeIcon: JSX.Element = questTypeToIcon(props.questType);
  const questTypeColor = questTypeToColor(props.questType);

  return (
    <ListGroupItem
      {...props.provided.draggableProps}
      {...props.provided.dragHandleProps}
      ref={props.provided.innerRef}
    >
      <Row className="align-items-center">
        <Col xs={3}>
          <CircleIcon color={questTypeColor} content={questTypeIcon} />
        </Col>
        <Col xs={7}>
          {props.position + 1} - {props.questInfo.name}
          <Row>
            <Col>
              <Badge pill>
                <FontAwesomeIcon icon={faStar} />
                &nbsp;{props.questInfo.points}
              </Badge>
            </Col>
            {props.questInfo.timeLimit && props.questInfo.timeLimit > 0 ? (
              <Col>
                <Badge pill bg="success">
                  <FontAwesomeIcon icon={faClock} />
                  &nbsp;
                  {secondsToTimeStringLiterals(props.questInfo.timeLimit)}
                </Badge>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Col>
        <Col xs={2}>
          <FontAwesomeIcon
            className="mx-1 fa-clickable"
            icon={faPen}
            onClick={() => editHandler()}
          />
          <FontAwesomeIcon
            className="mx-1 fa-clickable"
            icon={faTrash}
            onClick={() => setShow(true)}
          />
        </Col>
      </Row>
      <ActionConfirmationModal
        show={show}
        title="Vymazat úkol"
        body="Opravdu si přejete vymazat úkol?"
        confirmation="Vymazat úkol"
        onClickNo={() => setShow(false)}
        onClickYes={() => {
          deleteQuestHandler();
        }}
        confirmButtonVariant={"danger"}
      />
    </ListGroupItem>
  );
};

export default QuestListItem;
