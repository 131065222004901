import { ApiContextType } from "../contexts/ApiContext";

const ApiContextReducer = (state: ApiContextType, action: any) => {
  switch (action.type) {
    case "SIGN_IN":
      return { ...state, api: state.api.signIn(action.payload) };
    case "SIGN_OUT":
      return { ...state, api: state.api.signOut() };
    default:
      return state;
  }
};

export default ApiContextReducer;
