import useSWR, { SWRResponse } from "swr";
import {
  GameBriefInfoType,
  GameProgressInfoType,
  QuestInfoType,
  TeamInfoExportType,
  TeamInfoType,
  CreateGameInfoV2,
  QuestInfoV2,
  EditGameInfo,
  GameInfo,
} from "./datatypes";
import { ApiCallbacks, defaultCallbacks, noop } from "./utils";
import { toast } from "react-toastify";
import { ApiContextType } from "../contexts/ApiContext";
import Api from "./api";

export function useGamesList(): SWRResponse<GameBriefInfoType[], any> {
  return useSWR<GameBriefInfoType[]>("/v1/web/games");
}

export function useTemplatesList(): SWRResponse<GameBriefInfoType[], any> {
  return useSWR<GameBriefInfoType[]>("/v1/web/templates");
}

export function useGameInfo(game_id: string): SWRResponse<GameInfo, any> {
  return useSWR<GameInfo>(`/v1/web/game/${game_id}`);
}

export function useTeamsInGame(
  gameId: string,
  refresh: { interval: number; api: Api }
): SWRResponse<TeamInfoType[], any> {
  const url: string = getTeamsInGameApiUrl(gameId);
  return useSWR<TeamInfoType[]>(url, refresh.api.getFetcher<TeamInfoType[]>(), {
    refreshInterval: refresh.interval,
  });
}

export function useTeamsExport(
  gameId: string
): SWRResponse<TeamInfoExportType[], any> {
  return useSWR<TeamInfoExportType[]>(`/v1/web/game/${gameId}/teams?export=1`);
}

export function useTeamAnswers(
  teamId: string
): SWRResponse<GameProgressInfoType[], any> {
  return useSWR<GameProgressInfoType[]>(getTeamAnswersApiUrl(teamId));
}

export function useQuest(questId: string): SWRResponse<QuestInfoType, any> {
  return useSWR<QuestInfoType>(`/v1/web/quest/${questId}`);
}

export function useGameProgress(
  gameProgressId: string
): SWRResponse<GameProgressInfoType, any> {
  return useSWR<GameProgressInfoType>(
    `/v1/web/game-progress/${gameProgressId}`
  );
}

export function getTeamAnswersApiUrl(teamId: string): string {
  return `/v1/web/team/${teamId}/answers`;
}

export function getTeamsInGameApiUrl(gameId: string): string {
  return `/v1/web/game/${gameId}/teams`;
}

export function createGame(
  apiContext: ApiContextType,
  gameData: CreateGameInfoV2,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .post("/api/v2/web/game", gameData)
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function editQuestPosition(
  apiContext: ApiContextType,
  gameId: string,
  questId: string,
  questPosition: number,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .patch(`/api/v2/web/game/${gameId}/quest/${questId}/edit-position`, {
      questPosition: questPosition,
    })
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          case 404:
            toast.error("Hra nebo úkol nebyly nalezeny.");
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function editGame(
  apiContext: ApiContextType,
  gameId: string,
  gameData: EditGameInfo,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .patch(`/api/v2/web/game/${gameId}`, gameData)
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          case 404:
            toast.error("Hra nebyla nalezena.");
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function createQuest(
  apiContext: ApiContextType,
  gameId: string,
  questData: QuestInfoV2,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .post(`/api/v2/web/game/${gameId}/quest`, questData)
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function editQuest(
  apiContext: ApiContextType,
  gameId: string,
  questId: string,
  questData: QuestInfoV2,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .patch(`/api/v2/web/game/${gameId}/quest/${questId}`, questData)
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function deleteQuest(
  apiContext: ApiContextType,
  gameId: string,
  questId: string,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .delete(`/api/v2/web/game/${gameId}/quest/${questId}`)
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function duplicateGame(
  apiContext: ApiContextType,
  gameId: string,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .post(`/api/v1/web/game/${gameId}/duplicate`)
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function deleteGame(
  apiContext: ApiContextType,
  gameId: string,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .delete(`/api/v1/web/game/${gameId}`)
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function stopGame(
  apiContext: ApiContextType,
  gameId: string,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .post(`/api/v1/web/game/${gameId}/stop`)
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function changeAnswerPoints(
  apiContext: ApiContextType,
  gameProgressId: string,
  points: number,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .patch(`/api/v1/web/game-progress/${gameProgressId}`, {
      points: points,
    })
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function sendTeamMessage(
  apiContext: ApiContextType,
  gameId: string,
  teamId: string | undefined,
  message: string,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const query = teamId === undefined ? "" : `?teamId=${teamId}`;
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .post(`/api/v1/web/game/${gameId}/message${query}`, { message: message })
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}
