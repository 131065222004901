import React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../../App.css";

function CircularProgressWithLabel(
  props: CircularProgressProps & {
    value: number;
    children: React.ReactNode;
  }
) {
  return (
    <Box className="circular-container">
      <CircularProgress variant="determinate" {...props} />
      <Box className="circular-wrapper">
        {props.children}
        <Typography
          className="circular-text"
          variant="caption"
          component="div"
          color="primary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
