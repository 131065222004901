import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { ApiContext } from "../contexts/ApiContext";

function HandleError(error?: any) {
  const apiContext = useContext(ApiContext);
  // If we signed out or got 401, we need to redirect to the sign in page
  if (!apiContext.api.isSignedIn()) return <Redirect to="/signin" />;
  if (error) {
    throw error;
  }
  throw new Error("Unknown error: no details provided");
}

export default HandleError;
