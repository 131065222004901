import React from "react";

interface IProps {
  checked: boolean;
  onChange?: () => void;
}

const Switch = (props: IProps) => {
  return (
    <div className="switch-container">
      <label className={`opacity-${!props.checked ? 100 : 25}`}>Ne</label>
      <label className="switch">
        <input
          type="checkbox"
          onChange={props.onChange}
          checked={props.checked}
        />
        <span className="slider round"></span>
      </label>
      <label className={`opacity-${props.checked ? 100 : 25}`}>Ano</label>
    </div>
  );
};

export default Switch;
