import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import { calculateTimeLeft } from "../../utils";

interface GameStatusProps {
  children?: React.ReactNode;
  background: "badge-green" | "badge-yellow" | "badge-red";
  timeLimit?: number | null;
  timestampStart?: number | null;
}

function GameStatus({
  children,
  background,
  timeLimit,
  timestampStart,
}: GameStatusProps) {
  const classes = `dashboard-badge ${background}`;
  const [timeRemaining, setTimeRemaining] = useState(
    timeLimit && timestampStart
      ? calculateTimeLeft(timeLimit, timestampStart)
      : null
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(
        timeLimit && timestampStart
          ? calculateTimeLeft(timeLimit, timestampStart)
          : null
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [timeRemaining, timeLimit, timestampStart]);

  return (
    <Badge className={classes} bg="">
      {timeRemaining ? timeRemaining : children}
    </Badge>
  );
}

export default GameStatus;
