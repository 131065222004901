import { toast } from "react-toastify";
import { ApiCallbacks, defaultCallbacks, noop } from "./utils";
import { ApiContextType } from "../contexts/ApiContext";

export function changePasswordByToken(
  apiContext: ApiContextType,
  token: string,
  password: string,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .post(`/api/v1/web/user/change_pwd/${token}`, { new_pwd: password })
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          case 404:
            toast.error("Uživatel s daným emailem nebyl nalezen.");
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function resetPassword(
  apiContext: ApiContextType,
  email: string,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .post(`/api/v1/web/user/reset_pwd/${email}`)
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 404:
            toast.error("Uživatel se zadaným emailem neexistuje.");
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function changePassword(
  apiContext: ApiContextType,
  oldPassword: string,
  newPassword: string,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .patch(`/api/v1/web/user/change_pwd`, {
      old_pwd: oldPassword,
      new_pwd: newPassword,
    })
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 400:
            toast.error("Zadané staré heslo není správné.");
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}
