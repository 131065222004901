import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  changeAnswerPoints,
  getTeamAnswersApiUrl,
  getTeamsInGameApiUrl,
  useQuest,
} from "../../api/game";
import { ApiContext } from "../../contexts/ApiContext";
import { KeyedMutator, useSWRConfig } from "swr";
import { GameProgressInfoType } from "../../api/datatypes";
import { toast } from "react-toastify";
import ActionConfirmationModal from "../ActionConfirmationModal";
import HandleError from "../HandleError";
import { LoadingCenter } from "../Loading";

interface AnswerFormProps extends React.InputHTMLAttributes<HTMLInputElement> {
  questId: string;
  teamId: string;
  gameId: string;
  earnedPoints: number | null;
  penalizationTime: number | null;
  penalizationHelp: number | null;
  activeGameProgressId: string;
  mutateGameProgress: KeyedMutator<GameProgressInfoType>;
}

function AnswerForm(props: AnswerFormProps) {
  const [points, setPoints] = useState<number>(
    props.earnedPoints ? props.earnedPoints : 0
  );
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const apiContext = useContext(ApiContext);
  const { mutate } = useSWRConfig();

  const { data: quest, error } = useQuest(props.questId);
  if (error) return <HandleError error={error} />;
  if (!quest) return <LoadingCenter />;

  function handleSubmit() {
    changeAnswerPoints(apiContext, props.activeGameProgressId, points, {
      preApiCallback: () => {
        setApiError(false);
        setLoading(true);
      },
      thenCallback: () => {
        mutate(getTeamAnswersApiUrl(props.teamId));
        mutate(getTeamsInGameApiUrl(props.gameId));
        props.mutateGameProgress();
        toast.success("Body byly úspěšně přiděleny.");
      },
      errorCallback: () => {
        setApiError(true);
      },
      finallyCallback: () => {
        setLoading(false);
        setShowModal(false);
      },
    });
  }

  // The React.KeyboardEvent<FormControlElement>
  // is not fully compatible with the KeyboardEvent
  function handleKeyDown(event: any) {
    if (event.key === "Enter") {
      event.preventDefault();
      props.earnedPoints !== null ? setShowModal(true) : handleSubmit();
    }
  }

  const pHelp = props.penalizationHelp !== null ? props.penalizationHelp : 0;
  const pTime = props.penalizationTime !== null ? props.penalizationTime : 0;
  const pAnswer = props.earnedPoints !== null ? props.earnedPoints : 0;
  const pPenalization = pHelp + pTime;
  const pTotal = Math.max(pAnswer - pPenalization, 0);
  const pEarned = props.earnedPoints !== null ? pTotal : "-";

  return (
    <Row className="mt-4">
      <Col xs="6">
        <h5>Získáno</h5>
        {pEarned}/{quest.points} bodů
        <br />
        <small>
          Za správnou odpověď získáno {pAnswer} bodů
          <br />
          Penalizace {pPenalization} bodů
          <br />- {pTime} bodů za čas
          <br />- {pHelp} bodů za nápovědy
        </small>
      </Col>
      <Col xs="6">
        <Form>
          <Form.Label as="h5">Nový počet:</Form.Label>
          <Form.Group controlId="formPoints">
            <Form.Control
              required
              type="number"
              isInvalid={points < 0}
              placeholder="Zadejte body"
              onChange={(event) => setPoints(+event.target.value)}
              onKeyDown={(event) => handleKeyDown(event)}
            />
            <Form.Control.Feedback type="invalid">
              Nelze udělit záporné body.
            </Form.Control.Feedback>
          </Form.Group>
          {apiError && (
            <p>Udělení bodů se nezdařilo, zkuste to prosím znovu.</p>
          )}
          <Button
            onClick={
              props.earnedPoints !== null
                ? () => setShowModal(true)
                : () => handleSubmit()
            }
            disabled={loading}
            className="mt-2"
          >
            Udělit body
          </Button>
          <ActionConfirmationModal
            title={"Změnit body"}
            body={"Opravdu si přejete změnit udělené body?"}
            confirmation={"Změnit body"}
            show={showModal}
            onClickNo={() => setShowModal(false)}
            onClickYes={() => handleSubmit()}
            confirmButtonVariant={"danger"}
          />
        </Form>
      </Col>
    </Row>
  );
}

export default AnswerForm;
