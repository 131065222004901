import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { config } from "./constants";
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: config.production
      ? "InstrumentationKey=5d78e358-b685-4579-82c3-d66db91adb24;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/"
      : "",
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});
if (config.production) {
  appInsights.loadAppInsights();
  console.log("App insights loaded");
}
