import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { sendTeamMessage } from "../../api/game";
import { ApiContext } from "../../contexts/ApiContext";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface messageModalProps {
  show: boolean;
  onHide: () => void;
  gameid: string;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  teamId?: string;
  teamName?: string;
}

const MessageModal = (props: messageModalProps) => {
  const [message, setMessage] = useState("");
  const apiContext = useContext(ApiContext);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.teamId && props.teamName
            ? "Kontaktovat team: " + props.teamName
            : "Kontaktovat týmy"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Zpráva</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() =>
            sendTeamMessage(apiContext, props.gameid, props.teamId, message, {
              thenCallback: () => {
                toast.success("Zpráva byla odeslána");
                props.setModalShow(false);
              },
            })
          }
        >
          Kontaktovat
          <FontAwesomeIcon icon={faChevronRight} className="mx-2" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;
