import React from "react";
import Container from "react-bootstrap/Container";
import Footer from "./Footer";

function Page(props: any) {
  return (
    <>
      <Container fluid="xl">{props.children}</Container>
      <Footer />
    </>
  );
}

export default Page;
