import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CircleIcon from "../CircleIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { questTypeToColor, questTypeToIcon } from "../../utils";
import { GameProgressInfoType, QuestInfoType } from "../../api/datatypes";

function AnswerMenuItem(props: {
  questToEval: {
    quest: QuestInfoType;
    gameProgress: GameProgressInfoType;
  };
}) {
  const quest = props.questToEval.quest;

  const icon =
    quest.points === props.questToEval.gameProgress.points ? (
      <FontAwesomeIcon className="right-answer" icon={faCheckCircle} />
    ) : (
      <FontAwesomeIcon className="wrong-answer" icon={faTimesCircle} />
    );

  return (
    <Row className="text-left align-items-center">
      <Col xs="3">
        <CircleIcon
          color={questTypeToColor(quest.type)}
          content={questTypeToIcon(quest.type)}
        />
      </Col>
      <Col xs="7" xl="8">
        {quest.position} - {quest.name}
      </Col>
      <Col xs="2" xl="1">
        {icon}
      </Col>
    </Row>
  );
}

export default AnswerMenuItem;
