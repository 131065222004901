import useSWR, { SWRResponse } from "swr";
import { ApiCallbacks, defaultCallbacks, noop } from "./utils";
import { ApiContextType } from "../contexts/ApiContext";
import { toast } from "react-toastify";
import { AxiosProgressEvent } from "axios";

export function useGamePhotos(game_id: string): SWRResponse<string[], any> {
  return useSWR<string[]>(`/v1/photo/game/${game_id}`);
}

export function useGameProgressMedia(
  game_progress_id: string,
  questType: string
): SWRResponse<string[], any> {
  return useSWR<string[]>(`/v1/${questType}/game_progress/${game_progress_id}`);
}

export function useTeamPhotos(team_id: string): SWRResponse<string[], any> {
  return useSWR<string[]>(`/v1/photo/team/${team_id}`);
}

export function uploadPhoto(
  apiContext: ApiContextType,
  game_id: string,
  file: Blob,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  const formData = new FormData();
  formData.append("file", file);
  apiContext.api
    .getAxios()
    .post(`/api/v1/photo/game/${game_id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "cache-control": "no-cache",
      },
      onUploadProgress: onUploadProgress,
    })
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 404:
            toast.error("Hra nebyla nalezena.");
            break;
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}
