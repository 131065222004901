import React from "react";
import UserChangePassword from "../components/users/UserChangePassword";
import PageLogged from "../components/PageLogged";

const ChangePasswordPage = () => {
  return (
    <PageLogged>
      <UserChangePassword />
    </PageLogged>
  );
};

export default ChangePasswordPage;
