import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React from "react";
import { validateCoords } from "../../coordsUtils";

interface CoordsFormProps {
  title: string;
  coords: string;
  setCoords: React.Dispatch<React.SetStateAction<string>>;
  coordsTouched: boolean;
  setCoordsTouched: React.Dispatch<React.SetStateAction<boolean>>;
  required: boolean;
}

export default function CoordsForm({
  title,
  coords,
  setCoords,
  coordsTouched,
  setCoordsTouched,
  required,
}: CoordsFormProps) {
  return (
    <Row className="align-items-center">
      <Col xs={12} className="mt-5 mb-2">
        <h4>{title}</h4>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} xl={4} xxl={4} className="mb-3">
        <Form.Group controlId="formCoords">
          <Form.Label>Souřadnice</Form.Label>
          <Form.Control
            required={required}
            value={coords}
            isInvalid={
              required
                ? coordsTouched && !validateCoords(coords)
                : coordsTouched &&
                  coords.length !== 0 &&
                  !validateCoords(coords)
            }
            onChange={(e) => {
              setCoords(e.target.value);
            }}
            onBlur={() => setCoordsTouched(true)}
            type="input"
            placeholder="např. 50.7359739N, 15.7396211E"
          />
          <Form.Control.Feedback className="mb-3" type="invalid">
            Zadejte platné souřadnice.
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
  );
}
