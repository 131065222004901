import React, { Dispatch, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import MdEditor from "./MdEditor";
import { areStringsInArrayNonEmpty } from "../../arrayUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  intro: string[];
  setIntro: React.Dispatch<React.SetStateAction<string[]>>;
  gameId: string;
  setIntroValid: Dispatch<React.SetStateAction<boolean>>;
  isQuestCreating: boolean;
}

const Intro = (props: IProps) => {
  useEffect(() => {
    props.setIntroValid(areStringsInArrayNonEmpty(props.intro));
  }, [props]);

  const handleAddIntro = () => {
    props.setIntro([...props.intro, ""]);
  };

  const handleRemoveIntro = (i: number) => {
    const values = [...props.intro];
    const filteredValues = values.filter((input, index) => index !== i);

    props.setIntro([...filteredValues]);
  };

  const handleChangeIntro = (i: number, value: string) => {
    const values: any = [...props.intro];
    values[i] = value;
    props.setIntro(values);
  };

  return (
    <Row>
      {props.isQuestCreating ? (
        <Col xs={12} className="mt-5 mb-2">
          <h3>Příběh a poučení před úkolem</h3>
        </Col>
      ) : (
        <h4>Úvod hry</h4>
      )}
      {props.intro.map((intro, index) => (
        <Col xs="12" className="mb-3" key={index}>
          <Form.Group>
            <Form.Label>
              {props.isQuestCreating
                ? `Poučení před úkolem č. ${index + 1}`
                : `Úvod do hry č. ${index + 1}`}
              <Button onClick={() => handleRemoveIntro(index)} variant="link">
                <FontAwesomeIcon icon={faTimes} className="delete-icon" />
              </Button>
            </Form.Label>
            <MdEditor
              value={intro}
              setValue={(value: string) => handleChangeIntro(index, value)}
              gameId={props.gameId}
            />
          </Form.Group>
        </Col>
      ))}
      <Col xs="12" className="mb-3">
        <Button onClick={() => handleAddIntro()}>
          {props.isQuestCreating
            ? "Přidat poučení před úkolem"
            : "Přidat úvod hry"}
        </Button>
      </Col>
    </Row>
  );
};

export default Intro;
