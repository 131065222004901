import React, { Dispatch, useCallback, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

interface IProps {
  answer: string | null;
  setAnswer: Dispatch<React.SetStateAction<string | null>>;
  setInvalidAnswerMessage: Dispatch<React.SetStateAction<string | null>>;
}

const OPEN_DEFAULT_ANSWER = "";

const OpenAnswer = (props: IProps) => {
  const updateAnswer = React.useCallback(
    (answer: string) => {
      props.setAnswer(answer);
    },
    [props]
  );

  const validateOpenAnswer = useCallback(
    (answer: string) => {
      if (answer.trim().length === 0) {
        props.setInvalidAnswerMessage("Zadejte prosím odpověď.");
        return;
      }
      props.setInvalidAnswerMessage(null);
    },
    [props]
  );

  useEffect(() => {
    if (props.answer === null) updateAnswer(OPEN_DEFAULT_ANSWER);
  }, [props.answer, updateAnswer]);

  const answerInput =
    props.answer !== null ? props.answer : OPEN_DEFAULT_ANSWER;

  useEffect(() => {
    validateOpenAnswer(answerInput);
  }, [answerInput, validateOpenAnswer]);

  return (
    <Row>
      <Col xs="12">
        <Form.Group className="my-3" controlId="formQuestAnswer">
          <Form.Label>Odpověď</Form.Label>
          <Form.Control
            type="text"
            name="answer"
            value={answerInput}
            onChange={(e) => updateAnswer(e.target.value)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default OpenAnswer;
