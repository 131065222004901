import React from "react";
import Page from "./Page";
import Menu from "./Menu";
import CheckAuth from "./CheckAuth";

function PageLogged(props: any) {
  return (
    <>
      <Page>
        <Menu />
        <CheckAuth />
        {props.children}
      </Page>
    </>
  );
}

export default PageLogged;
