import React, { useState } from "react";
import QuestHeader from "./QuestHeader";
import Button from "react-bootstrap/Button";
import FormContent from "./FormContent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActionConfirmationModal from "../../ActionConfirmationModal";
import { ButtonGroup } from "react-bootstrap";
import { QuestInfoType, QuestType } from "../../../api/datatypes";
import { useHistory } from "react-router-dom";

interface IProps {
  gameId: string;
  questType: QuestType;
  finishCoords: [number, number] | null;
  quests: QuestInfoType[];
  questInfo: QuestInfoType | undefined;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateNewQuest = (props: IProps) => {
  const history = useHistory();
  const [showExitModal, setShowExitModal] = useState(false);

  const isQuestEditing = !!props.questInfo;

  return (
    <>
      <QuestHeader
        onBackButtonClick={() => {
          setShowExitModal(true);
          props.setUnsavedChanges(false);
        }}
        questType={props.questType}
        isQuestEditing={isQuestEditing}
      />
      <FormContent
        gameId={props.gameId}
        questType={props.questType}
        finishCoords={props.finishCoords}
        quests={props.quests}
        questInfo={props.questInfo}
        setUnsavedChanges={props.setUnsavedChanges}
      />
      <Row>
        <Col className="text-center">
          <ButtonGroup>
            <Button
              onClick={() => {
                setShowExitModal(true);
                props.setUnsavedChanges(false);
              }}
              form="questform"
              variant="secondary"
            >
              Zrušit
            </Button>
            <Button type="submit" form="questform" className="btn-success">
              {isQuestEditing ? "Upravit úkol" : "Přidat úkol"}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <ActionConfirmationModal
        show={showExitModal}
        title={isQuestEditing ? "Zrušit úpravu úkolu?" : "Zrušit úkol"}
        body={
          isQuestEditing
            ? "Opravdu chcete zrušit úpravu úkolu?"
            : "Opravdu chcete zrušit vytváření úkolu?"
        }
        confirmation="Ano, zrušit"
        cancellation="Ne, pokračovat"
        onClickNo={() => setShowExitModal(false)}
        onClickYes={() => {
          setShowExitModal(false);
          history.push(`/editor/${props.gameId}`);
        }}
        confirmButtonVariant={"danger"}
      />
    </>
  );
};

export default CreateNewQuest;
