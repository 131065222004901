import Api from "../../api/api";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const TeamMediaAudios = (props: { media: string[]; api: Api }) => {
  const media = props.media.map((audio, index) => {
    return (
      <Col
        key={index}
        className="p-2 d-flex justify-content-center"
        xs={12}
        md={6}
        lg={4}
      >
        <audio controls>
          <source src={`${audio}`} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </Col>
    );
  });
  return media.length !== 0 ? (
    <Row>{media}</Row>
  ) : (
    <p className="mt-4 mb-5 text-center">Žádné audio.</p>
  );
};

export default TeamMediaAudios;
