import React from "react";
import { GameInfo, QuestInfoType } from "../../api/datatypes";
import Actions from "./Actions";
import CreateGameForm from "./CreateGameForm";
import Header from "./Header";

interface IProps {
  gameId: string;
  game: GameInfo;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  quests: QuestInfoType[];
}

const CreateNewGame = (props: IProps) => {
  return (
    <>
      <Header />
      <CreateGameForm
        gameId={props.gameId}
        game={props.game}
        quests={props.quests}
        setUnsavedChanges={props.setUnsavedChanges}
      />
      <Actions />
    </>
  );
};

export default CreateNewGame;
