import React from "react";

function CircleIcon(props: { color: string; content: string | JSX.Element }) {
  return (
    <div
      className="circle"
      style={{
        backgroundColor: props.color,
      }}
    >
      <div className="circle-content">{props.content}</div>
    </div>
  );
}

export default CircleIcon;
