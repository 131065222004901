import React, { Dispatch, useCallback, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HelpType } from "../../../api/datatypes";

interface IProps {
  help: HelpType[];
  setHelp: Dispatch<React.SetStateAction<HelpType[]>>;
  setHelpValid: Dispatch<React.SetStateAction<boolean>>;
}

const QuestHelp = (props: IProps) => {
  const validateHelp = useCallback(
    (help: HelpType[]) => {
      props.setHelpValid(
        help.every((h) => h.text.trim().length > 0 && h.penalization > 0)
      );
    },
    [props]
  );

  useEffect(() => {
    validateHelp(props.help);
  }, [validateHelp, props]);

  const handleChangeInput = (i: number, e: any) => {
    const values: any = [...props.help];
    values[i][e.target.name] = e.target.value;
    props.setHelp(values);
  };

  const handleAddInput = () => {
    props.setHelp([
      ...props.help,
      {
        id: props.help.length,
        text: "",
        penalization: 0,
      },
    ]);
  };

  const handleRemoveInput = (i: number) => {
    const values = [...props.help];
    const filteredValues = values.filter((input, index) => index !== i);
    filteredValues.map((help, i) => {
      let toUpdateHelp = Object.assign({}, help);
      toUpdateHelp.id = i;
      return toUpdateHelp;
    });
    props.setHelp([...filteredValues]);
  };

  return (
    <Row>
      <Col xs={12} className="mt-5 mb-2">
        <h3>Nápověda</h3>
      </Col>
      <Col xs="12" className="mb-3">
        {props.help.map((help, index) => (
          <Row key={index}>
            <Col xs="12">
              <Form.Group className="my-3" controlId="formQuestQuestion">
                <Form.Label>Nápověda č. {index + 1}</Form.Label>
                <Button
                  className="m-2"
                  onClick={() => handleRemoveInput(index)}
                  variant="link"
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="1x"
                    className="delete-icon"
                  />
                </Button>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={help.text}
                  name="text"
                  onChange={(e) => handleChangeInput(index, e)}
                />
              </Form.Group>
            </Col>
            <Col xs={4} sm={3} md={3} lg={2} xl={2} xxl={2}>
              <Form.Group>
                <Form.Label>Penalizace</Form.Label>
                <Form.Control
                  className="my-2"
                  type="number"
                  value={help.penalization}
                  name="penalization"
                  onChange={(e) => handleChangeInput(index, e)}
                />
              </Form.Group>
            </Col>
          </Row>
        ))}
        <Button className="my-3" onClick={() => handleAddInput()}>
          <FontAwesomeIcon className="mx-3" icon={faPlus} />
          Přidat nápovědu
        </Button>
      </Col>
    </Row>
  );
};

export default QuestHelp;
