import React, { useState } from "react";
import CreateNewGame from "../components/gameeditor/CreateNewGame";
import { RouteComponentProps } from "react-router";
import { useGameInfo } from "../api/game";
import HandleError from "../components/HandleError";
import { LoadingPage } from "../components/Loading";
import PageLogged from "../components/PageLogged";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import ReloadConfirmation from "../components/ReloadConfirmation";

function GameEditorPage(props: RouteComponentProps<{ id: string }>) {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { data: game, error } = useGameInfo(props.match.params.id);

  if (error) return <HandleError error={error} />;
  if (!game) return <LoadingPage />;

  if (game.timestampStart !== null) {
    toast.error("Běžící hru nelze upravit.");
    return <Redirect to="/dashboard" />;
  }

  return (
    <PageLogged>
      <ReloadConfirmation when={unsavedChanges} />
      <CreateNewGame
        gameId={props.match.params.id}
        setUnsavedChanges={setUnsavedChanges}
        game={game}
        quests={game.quests}
      />
    </PageLogged>
  );
}

export default GameEditorPage;
