import React from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Page from "../components/Page";

function ErrorPage() {
  return (
    <Page>
      <Row className="mt-5 mb-1">
        <Col xs={12} className="text-center">
          <h3>Něco se pokazilo ...</h3>
          <p>
            Na opravě již pracujeme.
            <br />
            Akci opakujte prosím později.
          </p>
          <a href="/" className="link">
            Přejít na úvodní obrazovku.
          </a>
        </Col>
      </Row>
    </Page>
  );
}

export default ErrorPage;
