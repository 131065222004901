import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GameBriefInfoType, TeamInfoExportType } from "../../api/datatypes";
import { secondsToTimeString, sortTeamsByResults } from "../../utils";
import CircularProgressWithLabel from "./CircularProgress";
import TableGameResults from "./TableGameResults";
import { dateToString } from "../../utils";
import RatingWithPopover from "./RatingWithPopover";

const TeamGameResults = (props: {
  team: TeamInfoExportType;
  teams: TeamInfoExportType[];
  game: GameBriefInfoType;
}) => {
  const sortedTeams = sortTeamsByResults(props.teams);
  const teamOrder = sortedTeams.findIndex(
    (team) => team.name === props.team.name
  );
  const timeElapsedStr = secondsToTimeString(
    props.game.timestampEnd! - props.game.timestampStart!,
    false
  );
  const timeLimitStr = secondsToTimeString(props.game.timeLimit, false);
  const gameStartDate = new Date(props.game.timestampStart! * 1000);
  const timeStartStr = dateToString(gameStartDate);

  return (
    <>
      <Row>
        <Col xs={12}>
          <h4 className="mb-3">Přehled</h4>
        </Col>
      </Row>
      <Row className="justify-content-between text-center">
        <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4} className="mb-4">
          <h6>Start hry</h6>
          <strong>{timeStartStr}</strong>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4} className="mb-4">
          <h6>Čas hry</h6>
          <strong>
            {timeElapsedStr}/{timeLimitStr}
          </strong>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4} className="mb-4">
          <h6>Hodnocení hry</h6>
          <RatingWithPopover
            ratings={[props.team.rating]}
            note={props.team.ratingNote}
          />
        </Col>
      </Row>
      <Row className="mt-4 justify-content-between text-center align-items-center">
        <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4} className="mb-4">
          <h6>Umístění</h6>
          <h1>{teamOrder + 1}</h1>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4} className="mb-4">
          <h6>Splněno úkolů:</h6>
          <CircularProgressWithLabel
            className="position-relative"
            size="10rem"
            value={(props.team.completedQuests / props.game.questsCount) * 100}
          >
            <span className="points-avg position-absolute">
              {props.team.completedQuests} / {props.game.questsCount}
            </span>
          </CircularProgressWithLabel>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4} className="mb-4">
          <h6>Získáno bodů:</h6>
          <CircularProgressWithLabel
            className="position-relative"
            size="10rem"
            value={(props.team.points / props.game.totalPoints) * 100}
          >
            <span className="quests-avg position-absolute">
              {props.team.points}/{props.game.totalPoints}
            </span>
          </CircularProgressWithLabel>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h4 className="my-4">Žebříček</h4>
          <TableGameResults
            isExporting={true}
            gameId={props.game.id}
            teams={sortedTeams}
            gameTimeElapsed={timeElapsedStr}
            gameTimeLimit={timeLimitStr}
            questsCount={props.game.questsCount}
            pointsSum={props.game.totalPoints}
            exportingTeamName={props.team.name}
          />
        </Col>
      </Row>
    </>
  );
};

export default TeamGameResults;
