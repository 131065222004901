import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage";
import SignInPage from "./pages/SignInPage";
import SignOutPage from "./pages/SignOutPage";
import DashboardPage from "./pages/DashboardPage";
import ErrorPage from "./pages/ErrorPage";
import TemplatesPage from "./pages/TemplatesPage";
import UsersPage from "./pages/UsersPage";
import GamePage from "./pages/GamePage";
import GameEditorPage from "./pages/GameEditorPage";
import ResetPasswordRequestPage from "./pages/ResetPasswordRequestPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ConfirmPage from "./pages/ConfirmPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import GameResultsPage from "./pages/GameResultsPage";
import GameTeamResultsPage from "./pages/GameTeamResultsPage";
import QuestEditorPage from "./pages/QuestEditorPage";

function NotFound() {
  return <Redirect to="/" />;
}

const routes = () => (
  <Switch>
    <Route path="/" exact component={HomePage} />
    <Route path="/signout/" exact component={SignOutPage} />
    <Route path="/signin/" exact component={SignInPage} />
    <Route
      path="/signin/reset-password/"
      exact
      component={ResetPasswordRequestPage}
    />
    <Route
      path="/signin/reset-password/:token"
      exact
      component={ResetPasswordPage}
    />
    <Route path="/dashboard/" exact component={DashboardPage} />
    <Route path="/templates/" exact component={TemplatesPage} />
    <Route path="/users/" exact component={UsersPage} />
    <Route path="/user/confirm/:token" exact component={ConfirmPage} />
    <Route path="/user/password-change" exact component={ChangePasswordPage} />
    <Route path="/game/:id" exact component={GamePage} />
    <Route path="/results/game/:id" exact component={GameResultsPage} />
    <Route path="/results/team/:id" exact component={GameTeamResultsPage} />
    <Route path="/editor/:id" exact component={GameEditorPage} />
    <Route path="/editor/:gameId/quest" exact component={QuestEditorPage} />
    <Route
      path="/editor/:gameId/quest/:questId"
      exact
      component={QuestEditorPage}
    />
    <Route path="/error/" exact component={ErrorPage} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);

export default routes;
