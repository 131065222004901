import React from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";

interface ActionConfirmationModalProps {
  title: string;
  body: string;
  confirmation: string;
  cancellation?: string;
  show: boolean;
  onClickNo: () => void;
  onClickYes: () => void;
  confirmButtonVariant?: string;
}

function ActionConfirmationModal(props: ActionConfirmationModalProps) {
  return (
    <Modal show={props.show} onHide={props.onClickNo} animation={false}>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClickNo}>
          {props.cancellation ? props.cancellation : "Zrušit"}
        </Button>
        <Button
          variant={
            props.confirmButtonVariant ? props.confirmButtonVariant : "primary"
          }
          onClick={props.onClickYes}
        >
          {props.confirmation}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ActionConfirmationModal;
