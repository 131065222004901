import { useQuest } from "../../api/game";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState } from "react";
import { questTypeToHeader, questTypeToIcon } from "../../utils";
import HandleError from "../HandleError";
import { LoadingCenter } from "../Loading";
import MDEditor from "@uiw/react-md-editor";
import { QuestType } from "../../api/datatypes";
import AnswerQuiz from "./AnswerQuiz";
import AnswerMedia from "./AnswerMedia";
import Button from "react-bootstrap/Button";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AnswerDetails(props: {
  activeGameProgressId: string;
  answer: string | null;
  questId: string;
}) {
  const [showDesc, setshowDesc] = useState(false);

  const { data: quest, error } = useQuest(props.questId);
  if (error) return <HandleError error={error} />;
  if (!quest) return <LoadingCenter />;

  const questTypeIcon: JSX.Element = questTypeToIcon(quest.type);
  const questTypeHeader = questTypeToHeader(quest.type);

  return (
    <>
      <Row className="mt-4">
        <Col xs="12">
          <h4>{quest.name.toLocaleUpperCase()}</h4>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          <div className="float-right">
            {questTypeIcon}
            &nbsp;&nbsp;
            {questTypeHeader}
          </div>
          {showDesc && (
            <>
              <h5>Popis</h5> <MDEditor.Markdown source={quest.description} />
            </>
          )}
          <Button
            className="mt-2"
            size="sm"
            variant="outline-primary"
            onClick={() => setshowDesc(!showDesc)}
          >
            <FontAwesomeIcon icon={showDesc ? faAngleUp : faAngleDown} />
            {showDesc ? " Skrýt popis" : " Zobrazit popis "}
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          <h5>Otázka</h5>
          <MDEditor.Markdown source={quest.question} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          {(quest.type === QuestType.QUIZ_SINGLE_CHOICE ||
            quest.type === QuestType.QUIZ_MULTI_CHOICE) &&
            props.answer && (
              <AnswerQuiz answer={props.answer} quizAnswers={quest.answer} />
            )}
          {(quest.type === QuestType.PHOTO ||
            quest.type === QuestType.VIDEO ||
            quest.type === QuestType.AUDIO) && (
            <AnswerMedia
              activeGameProgressId={props.activeGameProgressId}
              questType={quest.type}
            />
          )}
          {quest.type === QuestType.OPEN && props.answer && (
            <>
              <h5>Správná odpověď</h5>
              <p>{quest.answer}</p>
              <h5>Odpověď týmu</h5>
              <p>{props.answer}</p>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default AnswerDetails;
