import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import QuestList from "./QuestList";
import QuestsMap from "../map/QuestsMap";
import { QuestInfoType } from "../../api/datatypes";

interface IProps {
  gameId: string;
  gameFinishCoords?: [number, number];
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  quests: QuestInfoType[];
}

const Quests = (props: IProps) => {
  return (
    <Row className="mt-3">
      <h4>Úkoly</h4>
      <Col xs={12} sm={5} md={4} lg={4} xl={4} xxl={4}>
        <QuestList
          gameId={props.gameId}
          quests={props.quests}
          setUnsavedChanges={props.setUnsavedChanges}
        />
      </Col>

      <Col className="p-0" xs={12} sm={7} md={8} lg={8} xl={8} xxl={8}>
        <QuestsMap
          quests={props.quests}
          gameFinishCoords={props.gameFinishCoords}
          adjustBounds={true}
        />
      </Col>
    </Row>
  );
};

export default Quests;
