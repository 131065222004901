import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GameInfo } from "../../api/datatypes";
import { secondsToTimeString, sortTeamsByResults } from "../../utils";
import { useTeamsExport } from "../../api/game";
import Error from "../../pages/ErrorPage";
import CircularProgressWithLabel from "./CircularProgress";
import Button from "react-bootstrap/Button";
import TableGameResults from "./TableGameResults";
import { sumArray, average } from "../../arrayUtils";
import { dateToString } from "../../utils";
import { LoadingCenter } from "../Loading";
import "../../App.css";
import Evaluation from "../evaluation/Evaluation";
import RatingWithPopover from "./RatingWithPopover";

const round1decimal = (value: number): number =>
  Math.round(value * 10 + Number.EPSILON) / 10;

const GameResults = (props: { game: GameInfo }) => {
  const [showEvaluation, setShowEvaluation] = useState(false);
  const { data: teams, error } = useTeamsExport(props.game.id);
  if (error) return <Error />;
  if (!teams) return <LoadingCenter />;
  const timeElapsedStr = secondsToTimeString(
    props.game.timestampEnd! - props.game.timestampStart!,
    false
  );
  const timeLimitStr = secondsToTimeString(props.game.timeLimit, false);
  const gameStartDate = new Date(props.game.timestampStart! * 1000);
  const timeStartStr = dateToString(gameStartDate);
  const teamsCount = teams.length;
  const questsCount = props.game.quests.length;
  const pointsSum = sumArray(props.game.quests.map((quest) => quest.points));
  const sortedTeams = sortTeamsByResults(teams);
  const questsAvg = round1decimal(
    average(teams.map((team) => team.completedQuests))
  );
  const pointsAvg = round1decimal(average(teams.map((team) => team.points)));
  const teamRatings = teams.map((team) => team.rating);

  return (
    <>
      <Row>
        <Col xs={12}>
          <h4 className="mb-3">Přehled</h4>
        </Col>
      </Row>
      <Row className="justify-content-between text-center">
        <Col xs={12} sm={6} md={6} lg={3} xl={3} xxl={2} className="mb-4">
          <h6>Start hry</h6>
          <strong>{timeStartStr}</strong>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3} xl={3} xxl={2} className="mb-4">
          <h6>Délka trvání hry</h6>
          <strong>
            {timeElapsedStr}/{timeLimitStr}
          </strong>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3} xl={3} xxl={2} className="mb-4">
          <h6>Počet týmů ve hře</h6>
          <strong>{teamsCount}</strong>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3} xl={3} xxl={2} className="mb-4">
          <h6>Průměrné hodnocení</h6>
          <RatingWithPopover ratings={teamRatings} />
        </Col>
      </Row>
      <Row className="mt-4 justify-content-around text-center">
        <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} className="mb-4">
          <h6>Průměrně splněno úkolů:</h6>
          <CircularProgressWithLabel
            className="position-relative"
            size="10rem"
            value={(questsAvg / questsCount) * 100}
          >
            <span className="quests-avg position-absolute">
              {questsAvg}/{questsCount}
            </span>
          </CircularProgressWithLabel>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} className="mb-4">
          <h6>Průměrně získáno bodů:</h6>
          <CircularProgressWithLabel
            className="position-relative"
            size="10rem"
            value={(pointsAvg / pointsSum) * 100}
          >
            <span className="points-avg position-absolute">
              {pointsAvg}/{pointsSum}
            </span>
          </CircularProgressWithLabel>
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <h4 className="mb-3">Týmy</h4>
        </Col>
        <Col xs={8}>
          <Button
            className="float-right px-4"
            onClick={() => setShowEvaluation(!showEvaluation)}
          >
            {showEvaluation ? "Zpět na přehled" : "Upravit body"}
          </Button>
        </Col>
      </Row>
      {showEvaluation ? (
        <Evaluation gameId={props.game.id} quests={props.game.quests} />
      ) : (
        <Row>
          <Col xs={12}>
            <TableGameResults
              isExporting={false}
              gameId={props.game.id}
              teams={sortedTeams}
              gameTimeElapsed={timeElapsedStr}
              gameTimeLimit={timeLimitStr}
              questsCount={questsCount}
              pointsSum={pointsSum}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default GameResults;
