import React, { useContext, useState } from "react";
import Page from "../components/Page";
import HandleError from "../components/HandleError";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { changePasswordByToken } from "../api/password";
import { RouteComponentProps } from "react-router";
import { Redirect } from "react-router-dom";
import { ApiContext } from "../contexts/ApiContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const MIN_PASSWORD_LENGTH = 8;

function ResetPasswordRequestPage(
  props: RouteComponentProps<{ token: string }>
) {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const apiContext = useContext(ApiContext);

  function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    changePasswordByToken(apiContext, props.match.params.token, password1, {
      preApiCallback: () => {
        setError(false);
        setLoading(true);
      },
      thenCallback: (response) => {
        setResetSuccess(true);
      },
      errorCallback: () => {
        setError(true);
      },
      finallyCallback: () => {
        setLoading(false);
      },
    });
  }

  function validateForm() {
    return password1 === password2 && password1.length > MIN_PASSWORD_LENGTH;
  }

  if (error && !loading) {
    return <HandleError error={error} />;
  }
  if (resetSuccess && !loading) {
    return <Redirect to={"/signin"} />;
  }
  return (
    <Page>
      <Row className="justify-content-center mt-5 mb-1">
        <Col xs={12} sm={8} md={6} lg={5} xl={5} xxl={4}>
          <h1>Obnovit heslo</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="password1" className="mt-4">
              <Form.Label>Nové heslo:</Form.Label>
              <Form.Control
                autoFocus
                required
                type="password"
                value={password1}
                onChange={(event) => setPassword1(event.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="password2" className="mt-2">
              <Form.Label>Znovu nové heslo pro kontrolu:</Form.Label>
              <Form.Control
                required
                type="password"
                value={password2}
                onChange={(event) => setPassword2(event.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={!validateForm() || loading}
              className="mt-3"
            >
              Resetovat heslo
            </Button>
          </Form>
        </Col>
      </Row>
    </Page>
  );
}

export default ResetPasswordRequestPage;
