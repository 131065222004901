import React, { useState } from "react";
import { TeamInfoType } from "../../api/datatypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faCheckCircle,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CircleIcon from "../CircleIcon";
import { Colors } from "../../utils";
import Badge from "react-bootstrap/Badge";
import MessageModal from "./MessageModal";
import Button from "react-bootstrap/Button";

interface TeamProps {
  team: TeamInfoType;
  gameId: string;
  questsCount: number;
}

function Team(props: TeamProps) {
  const [modalShow, setModalShow] = useState(false);
  return (
    <Row className="text-left align-items-center">
      <Col xs={3}>
        <CircleIcon color={Colors.RED} content={props.team.name[0]} />
      </Col>
      <Col xs={9}>
        <Row>
          <Col xs="12">{props.team.name}</Col>
        </Row>
        <Row>
          <Col>
            <Badge pill bg="success">
              <FontAwesomeIcon icon={faCheckCircle} />
              &nbsp;{props.team.completedQuests}/{props.questsCount}
            </Badge>
          </Col>
          <Col>
            <Badge pill>
              <FontAwesomeIcon icon={faStar} />
              &nbsp;{props.team.points}
            </Badge>
          </Col>
          <Col>
            <Button
              variant="link"
              onClick={() => setModalShow(true)}
              className="m-0 p-0"
            >
              <Badge pill bg="info">
                <FontAwesomeIcon icon={faEnvelope} />
              </Badge>
            </Button>
          </Col>
        </Row>
      </Col>
      <MessageModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        gameid={props.gameId}
        teamId={props.team.id}
        teamName={props.team.name}
        setModalShow={setModalShow}
      />
    </Row>
  );
}

export default Team;
