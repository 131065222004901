import { AxiosResponse } from "axios";

export function noop() {}

export interface ApiCallbacks<T> {
  preApiCallback?: () => void;
  thenCallback?: (response: AxiosResponse<T>) => void;
  errorCallback?: (response: AxiosResponse<any>) => void;
  finallyCallback?: () => void;
}

export const defaultCallbacks: ApiCallbacks<any> = {
  preApiCallback: noop,
  thenCallback: noop,
  errorCallback: noop,
  finallyCallback: noop,
};
