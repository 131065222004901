import React from "react";
import MDEditor, { commands } from "@uiw/react-md-editor";
import ImageUpload from "./ImageUpload";
import VideoUpload from "./VideoUpload";
import { faImage, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  value: string;
  setValue: (value: string) => void;
  gameId: string;
}

const MdEditor = (props: IProps) => {
  return (
    <MDEditor
      value={props.value}
      onChange={(val) => {
        props.setValue(val!);
      }}
      commands={[
        commands.bold,
        commands.italic,
        commands.quote,
        commands.code,
        commands.link,
        commands.hr,
        commands.orderedListCommand,
        commands.unorderedListCommand,
        commands.group(
          [
            commands.title1,
            commands.title2,
            commands.title3,
            commands.title4,
            commands.title5,
            commands.title6,
          ],
          {
            name: "title",
            groupName: "title",
            buttonProps: { "aria-label": "Insert title" },
          }
        ),
        commands.divider,
        commands.group([], {
          name: "upload",
          groupName: "upload",
          icon: <FontAwesomeIcon icon={faImage} />,
          children: (handle: any) => {
            return <ImageUpload handle={handle} gameId={props.gameId} />;
          },
          buttonProps: { "aria-label": "Insert image" },
        }),
        commands.group([], {
          name: "youtube",
          groupName: "youtube",
          icon: <FontAwesomeIcon icon={faVideo} />,
          children: (handle: any) => {
            return <VideoUpload handle={handle} gameId={props.gameId} />;
          },
          buttonProps: { "aria-label": "Insert image" },
        }),
      ]}
    />
  );
};

export default MdEditor;
