import useSWR, { SWRResponse } from "swr";
import { UserType } from "./datatypes";
import { ApiCallbacks, defaultCallbacks, noop } from "./utils";
import { toast } from "react-toastify";
import { ApiContextType } from "../contexts/ApiContext";

export function useUsers(): SWRResponse<UserType[], any> {
  return useSWR<UserType[]>(`/v1/web/users`);
}

export function inviteUser(
  apiContext: ApiContextType,
  email: string,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .post("/api/v1/web/user", {
      email: email,
    })
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function deleteUser(
  apiContext: ApiContextType,
  userId: string,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .delete(`/api/v1/web/user/${userId}`)
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}

export function confirmUser(
  apiContext: ApiContextType,
  name: string,
  password: string,
  token: string,
  callbacks: ApiCallbacks<any> = {
    preApiCallback: noop,
    thenCallback: noop,
    errorCallback: noop,
    finallyCallback: noop,
  }
): void {
  const mCallbacks = { ...defaultCallbacks, ...callbacks };
  // @ts-ignore can not be undefined because of the merge with defaultCallbacks
  mCallbacks.preApiCallback();
  apiContext.api
    .getAxios()
    .put("/api/v1/web/user/confirm", {
      name: name,
      password: password,
      token: token,
    })
    .then((response) => {
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.thenCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        switch (error.response.status) {
          case 401:
            toast.error("Vaše přihlášení vypršelo.", {
              toastId: "error-401",
            });
            apiContext.signOut();
            break;
          default:
            toast.error(`Nastala neznámá chyba: ${error.response.status}`);
            break;
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      // @ts-ignore can not be undefined because of the merge with defaultCallbacks
      mCallbacks.errorCallback(error);
    })
    .finally(mCallbacks.finallyCallback);
}
