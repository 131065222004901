import React from "react";
import { Prompt } from "react-router-dom";

const initBeforeUnLoad = (showExitPrompt: boolean = true) => {
  window.onbeforeunload = (event) => {
    // Show prompt based on state
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  };
};

function ReloadConfirmation(props: { when: boolean }) {
  initBeforeUnLoad(props.when);

  return (
    <Prompt
      when={props.when}
      message={(location, action) => {
        return "Všechny provedené změny budou ztraceny, pokračovat?";
      }}
    />
  );
}

export default ReloadConfirmation;
