import React from "react";
import Users from "../components/users/Users";
import PageLogged from "../components/PageLogged";

function UsersPage() {
  return (
    <PageLogged>
      <Users />
    </PageLogged>
  );
}

export default UsersPage;
