import React from "react";
import Rating from "@mui/material/Rating";
import { TeamRatingType } from "../../api/datatypes";
import { average } from "../../arrayUtils";
import { useTeamsExport } from "../../api/game";
import Error from "../../pages/ErrorPage";
import { LoadingCenter } from "../Loading";

function OverallRating({ gameId }: { gameId: string }) {
  const { data: teams, error } = useTeamsExport(gameId);
  if (error) return <Error />;
  if (!teams) return <LoadingCenter />;

  const teamRatings = teams
    .map((team) => team.rating)
    .filter((r): r is TeamRatingType => r !== null);
  if (teamRatings.length === 0) {
    return (
      <div className="small">
        Hodnocení: <b>neuděleno</b>
      </div>
    );
  }
  const averageRatings = teamRatings.map((r) => average(Object.values(r)));
  const averageOverallRating = average(averageRatings);
  const roundedAvgRating = (Math.round(averageOverallRating * 10) / 10).toFixed(
    1
  );

  return (
    <div className="small d-flex gap-2">
      <div>
        Hodnocení: <b>{roundedAvgRating}</b>
      </div>
      <Rating
        className="align-self-center"
        name="half-rating-read"
        size="small"
        value={averageOverallRating}
        precision={0.5}
        readOnly
      />
    </div>
  );
}

export default OverallRating;
