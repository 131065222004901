// Constants file for distinguishing between dev and prd

interface Config {
  production: boolean;
}

const prd: Config = {
  production: true,
};

const dev: Config = {
  production: false,
};

export const config: Config =
  process.env.NODE_ENV === "development" ? dev : prd;
