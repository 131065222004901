import Api from "../../api/api";
import React, { useState } from "react";
import ImageListItem from "@mui/material/ImageListItem";
import ImageList from "@mui/material/ImageList";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";

interface PhotoModalProps {
  show: boolean;
  onHide: () => void;
  image: string;
}

const PhotoModal = (props: PhotoModalProps) => {
  return (
    <Modal
      dialogClassName="image-modal"
      contentClassName="image-modal-content"
      show={props.show}
      onHide={props.onHide}
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Image className="image" fluid={true} src={props.image} />
      </Modal.Body>
    </Modal>
  );
};

const TeamMediaImages = (props: { media: string[]; api: Api }) => {
  const [showModal, setShowModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const media = props.media.map((image, index) => {
    return (
      <ImageListItem key={index}>
        <img
          onClick={() => {
            setShowModal(true);
            setImageUrl(`${image}`);
          }}
          src={`${image}-512`}
          alt={`Team answer ${index}`}
        />
      </ImageListItem>
    );
  });
  return media.length !== 0 ? (
    <ImageList variant="masonry" cols={4} gap={8}>
      {media}
      <PhotoModal
        show={showModal}
        onHide={() => setShowModal(false)}
        image={imageUrl}
      />
    </ImageList>
  ) : (
    <p className="mt-4 mb-5 text-center">Žádné foto.</p>
  );
};

export default TeamMediaImages;
