import React, { useState, useContext } from "react";
import Page from "../components/Page";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ApiContext } from "../contexts/ApiContext";
import { confirmUser } from "../api/user";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";

const ConfirmPage = (props: RouteComponentProps<{ token: string }>) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiError, setApiError] = useState(false);
  const apiContext = useContext(ApiContext);

  const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    confirmUser(apiContext, name, password, props.match.params.token, {
      thenCallback: () => {
        toast.success("Aktivace účtu proběhla úspěšně.");
        setApiSuccess(true);
      },
      errorCallback: () => {
        setApiError(true);
      },
    });
  };

  const validatePassword = () => {
    return password === confirmPassword && password.length >= 8;
  };

  if (apiSuccess) {
    return (
      <Page>
        <Row className="justify-content-center mt-5 mb-1 text-center">
          <Col xs={12} sm={12} md={10} lg={8} xl={7} xxl={6}>
            <p>Účet byl úspěšně aktivován.</p>
            <a href="/">Pokračovat přihlášením</a>
          </Col>
        </Row>
      </Page>
    );
  }

  if (apiError) {
    return (
      <Page>
        <Row className="justify-content-center mt-5 mb-1 text-center">
          <Col xs={12} sm={12} md={10} lg={8} xl={7} xxl={6}>
            <p>
              Při aktivaci účtu došlo k chybě.
              <br />
              Platnost Vaší pozvánky vypršela nebo již byla použita.
            </p>
          </Col>
        </Row>
      </Page>
    );
  }

  return (
    <Page>
      <h3 className="mb-3 mt-5 text-center">Aktivace nového účtu</h3>
      <Col
        xs={{ span: 10, offset: 1 }}
        sm={{ span: 8, offset: 2 }}
        md={{ span: 6, offset: 3 }}
      >
        <Form onSubmit={handleConfirm}>
          <Row className="justify-content-center">
            <Form.Group className="mb-4" controlId="name">
              <Form.Label>Jméno a příjmení</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Jméno a příjmení"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="justify-content-center">
            <Form.Group className="mb-4" controlId="password">
              <Form.Label>Heslo</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Heslo"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="justify-content-center">
            <Form.Group className="mb-4" controlId="confirmPassword">
              <Form.Label>Heslo pro potvrzení</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Heslo pro potvrzení"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="justify-content-center">
            <Button
              className="w-50"
              variant="primary"
              type="submit"
              disabled={!validatePassword()}
            >
              Potvrdit pozvánku
            </Button>
          </Row>
        </Form>
      </Col>
    </Page>
  );
};

export default ConfirmPage;
