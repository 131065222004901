import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ApiContext } from "../../contexts/ApiContext";
import { createGame, useGamesList } from "../../api/game";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
interface IProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateGameModal = (props: IProps) => {
  const history = useHistory();
  const { mutate } = useGamesList();
  const apiContext = useContext(ApiContext);
  const [gameName, setGameName] = useState("");
  const [isFormTouched, setIsFormTouched] = useState(false);

  const isFormValid = () => gameName.length > 0;

  const createNewGame = () => {
    setIsFormTouched(true);
    if (!isFormValid()) return;
    createGame(
      apiContext,
      {
        name: gameName,
        isTemplate: false,
      },
      {
        thenCallback: (res) => {
          mutate();
          toast.success("Hra byla vytvořena.");
          history.push("/editor/" + res.data);
        },
      }
    );
    props.setShowModal(false);
  };

  return (
    <Modal show={props.showModal} onHide={() => props.setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Vytvořit hru</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-2">
          <Form.Control
            type="text"
            placeholder="Zadejte název hry"
            onBlur={() => setIsFormTouched(true)}
            value={gameName}
            onChange={(e) => setGameName(e.target.value)}
          />
          {!isFormValid() && isFormTouched && (
            <p className="invalid-input">Zadejte prosím jméno hry</p>
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.setShowModal(false)}>
          Zrušit
        </Button>
        <Button variant="primary" onClick={() => createNewGame()}>
          Vytvořit hru
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateGameModal;
