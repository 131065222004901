import React from "react";
import { useGameInfo } from "../api/game";
import { Redirect, RouteComponentProps } from "react-router";
import HandleError from "../components/HandleError";
import GameResults from "../components/gameresults/GameResults";
import { LoadingPage } from "../components/Loading";
import PageLogged from "../components/PageLogged";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const GameResultsPage = (props: RouteComponentProps<{ id: string }>) => {
  const { data: game, error } = useGameInfo(props.match.params.id);

  if (error) return <HandleError error={error} />;
  if (!game) return <LoadingPage />;
  if (game.timestampEnd === null) return <Redirect to={`/game/${game.id}`} />;

  return (
    <PageLogged>
      <Row className="mt-5 mb-3">
        <Col xs="12">
          <h3>Výsledky hry - {game.name}</h3>
        </Col>
      </Row>
      <GameResults game={game} />
    </PageLogged>
  );
};

export default GameResultsPage;
