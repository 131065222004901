import React from "react";
import { QuestInfoType } from "../../api/datatypes";
import MapWithMarkers from "./MapWithMarkers";
import { questTypeToHeader, secondsToTimeStringLiterals } from "../../utils";
import { activeMarker } from "./mapDatatypes";

interface IProps {
  quests: QuestInfoType[];
  gameFinishCoords?: [number, number];
  questCoords?: activeMarker;
  adjustBounds?: boolean;
}

const QuestsMap = (props: IProps) => {
  const questToPopupText = (quest: QuestInfoType): JSX.Element => {
    return (
      <>
        <strong>{quest.name}</strong> <br />
        Typ úkolu: {questTypeToHeader(quest.type)} <br />
        Maximální počet bodů: {quest.points} <br />
        {quest.timeLimit
          ? `Čas na splnění: ${secondsToTimeStringLiterals(quest.timeLimit)}`
          : ""}
      </>
    );
  };

  return (
    <MapWithMarkers<QuestInfoType>
      markerItems={{
        list: props.quests,
        itemToCoords: (item: QuestInfoType) => item.coords,
        itemToPopupText: questToPopupText,
        adjustBounds: props.adjustBounds,
      }}
      finishCoords={props.gameFinishCoords}
      activeMarker={props.questCoords}
    />
  );
};
export default QuestsMap;
