import { QuestType, TeamInfoExportType, TeamInfoType } from "./api/datatypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faCamera,
  faMicrophone,
  faVideo,
  faCommentDots,
  faExclamationCircle,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

export enum Colors {
  GREEN = "#008606",
  LIGHT_GREEN = "#00BB8E",
  BLUE = "#0059be",
  YELLOW = "#FFA621",
  PURPLE = "#8728FF",
  LIGHT_BLUE = "#28CBFF",
  PINK = "#DC00AC",
  DARK_BLUE = "#00488A",
  RED = "#E14850",
  WARNING_YELLOW = "#F2AA4C",
  SUCCESS_GREEN = "#00BC13",
}

export const parseJwt = (token: string): any => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const secondsToTimeString = (
  inputSeconds: number,
  showSeconds: boolean = true
): string => {
  const absInputSeconds = Math.abs(inputSeconds);
  const hours = Math.floor(absInputSeconds / 3600);
  const minutes = Math.floor((absInputSeconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor(absInputSeconds % 60)
    .toString()
    .padStart(2, "0");
  const sign = inputSeconds < 0 ? "-" : "";

  if (showSeconds) return `${sign}${hours}:${minutes}:${seconds}`;
  else return `${sign}${hours}:${minutes}`;
};

export const secondsToTimeStringLiterals = (inputSeconds: number): string => {
  const absInputSeconds = Math.abs(inputSeconds);
  const hours = Math.floor(absInputSeconds / 3600);
  const minutes = +Math.floor((absInputSeconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = +Math.floor(absInputSeconds % 60)
    .toString()
    .padStart(2, "0");
  const sign = inputSeconds < 0 ? "-" : "";
  return `${sign}${hours > 0 ? `${hours}h` : ""}${
    minutes > 0 ? `${minutes}m` : ""
  }${seconds > 0 ? `${seconds}s` : ""}`;
};

export const questTypeToHeader = (questType: QuestType): string => {
  switch (questType) {
    case QuestType.QUIZ_SINGLE_CHOICE:
    case QuestType.QUIZ_MULTI_CHOICE:
      return "Kvíz";
    case QuestType.VIDEO:
      return "Video";
    case QuestType.PHOTO:
      return "Foto";
    case QuestType.AUDIO:
      return "Audio";
    case QuestType.LOCATION:
      return "Dojít na místo";
    case QuestType.EVENT:
      return "Událost";
    case QuestType.OPEN:
      return "Otevřená odpověď";
    default:
      throw new Error("Unknown quest type");
  }
};

export const questTypeToIcon = (
  questType: QuestType,
  size: SizeProp = "1x"
): JSX.Element => {
  switch (questType) {
    case QuestType.QUIZ_SINGLE_CHOICE:
    case QuestType.QUIZ_MULTI_CHOICE:
      return <FontAwesomeIcon icon={faLightbulb} size={size} inverse />;
    case QuestType.VIDEO:
      return <FontAwesomeIcon icon={faVideo} size={size} inverse />;
    case QuestType.PHOTO:
      return <FontAwesomeIcon icon={faCamera} size={size} inverse />;
    case QuestType.AUDIO:
      return <FontAwesomeIcon icon={faMicrophone} size={size} inverse />;
    case QuestType.LOCATION:
      return <FontAwesomeIcon icon={faMapMarkedAlt} size={size} inverse />;
    case QuestType.EVENT:
      return <FontAwesomeIcon icon={faExclamationCircle} size={size} inverse />;
    case QuestType.OPEN:
      return <FontAwesomeIcon icon={faCommentDots} size={size} inverse />;
    default:
      throw new Error("Unknown quest type");
  }
};

export const questTypeToColor = (questType: QuestType): string => {
  switch (questType) {
    case QuestType.QUIZ_SINGLE_CHOICE:
    case QuestType.QUIZ_MULTI_CHOICE:
      return Colors.PURPLE;
    case QuestType.VIDEO:
      return Colors.PINK;
    case QuestType.PHOTO:
      return Colors.DARK_BLUE;
    case QuestType.AUDIO:
      return Colors.LIGHT_BLUE;
    case QuestType.LOCATION:
      return Colors.LIGHT_GREEN;
    case QuestType.EVENT:
      return Colors.LIGHT_GREEN;
    case QuestType.OPEN:
      return Colors.RED;
    default:
      throw new Error("Unknown quest type");
  }
};

export const truncateString = (value: string, maxLength: number): string => {
  if (value.length > maxLength) return value.substr(0, maxLength - 4) + "...";
  else return value;
};

export const mapBounds = (
  coordsArr: [number, number][]
): [[number, number], [number, number]] => {
  const maxCorner = coordsArr.reduce(([lat, long], coords) => {
    return [
      coords[0] > lat ? coords[0] : lat,
      coords[1] > long ? coords[1] : long,
    ];
  });
  const minCorner = coordsArr.reduce(([lat, long], coords) => {
    return [
      coords[0] < lat ? coords[0] : lat,
      coords[1] < long ? coords[1] : long,
    ];
  });

  return [maxCorner, minCorner];
};

export const dateToString = (dateToString: Date, showTime: boolean = true) => {
  const date = dateToString.toLocaleDateString("cs");
  const time = dateToString.toLocaleTimeString("cs", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return showTime ? `${date} ${time}` : date;
};

export const sortTeamsByResults = (teams: TeamInfoExportType[]) => {
  const sortedTeams = teams.slice();
  sortedTeams.sort((a, b) => {
    if (a.points === b.points && a.completedQuests === b.completedQuests) {
      if (a.name < b.name) {
        return 1;
      } else {
        return -1;
      }
    } else if (a.points === b.points) {
      return a.completedQuests - b.completedQuests;
    }
    return b.points - a.points;
  });
  return sortedTeams;
};

export const locationOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

export const getPosition = (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, locationOptions)
  );
};

export const sortTeamsByName = (teams: TeamInfoType[]) => {
  const sortedTeams = teams.slice();
  sortedTeams.sort((a: TeamInfoType, b: TeamInfoType) => {
    if (a.name < b.name) {
      return -1;
    } else {
      return 1;
    }
  });
  return sortedTeams;
};

export const normalizeString = (text: string): string => {
  // Removes accent, multiple spaces are replaced by one, removes newlines and tabs, makes it lowercase
  return text
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase()
    .replace(/\s\s+/g, " ");
};

export const calculateTimeLeft = (
  timeLimit: number,
  timestampStart: number | null
) => {
  if (timestampStart === null) {
    return secondsToTimeString(timeLimit, false);
  } else {
    const timeNow: number = Date.now() / 1000;
    const secondsRemaining = timeLimit - (timeNow - timestampStart);
    return secondsToTimeString(secondsRemaining);
  }
};
