import React from "react";
import Dashboard from "../components/dashboard/Dashboard";
import PageLogged from "../components/PageLogged";

function DashboardPage() {
  return (
    <PageLogged>
      <Dashboard />
    </PageLogged>
  );
}

export default DashboardPage;
