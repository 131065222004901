import { QuestInfoType } from "./api/datatypes";

export const sumArray = (arr: number[]): number =>
  arr.reduce((p, c) => p + c, 0);

export const average = (arr: number[]): number => sumArray(arr) / arr.length;

export const areStringsInArrayNonEmpty = (stringArray: string[]) =>
  stringArray.every((value: string) => value.trim().length > 0);

export const sortQuestsByPosition = (questsArray: QuestInfoType[]) => {
  const sortedArray = [...questsArray];
  sortedArray.sort(function (a: QuestInfoType, b: QuestInfoType) {
    return a.position - b.position;
  });
  return sortedArray;
};
