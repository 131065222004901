import Api from "../../api/api";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const TeamMediaVideos = (props: { media: string[]; api: Api }) => {
  const media = props.media.map((video, index) => {
    return (
      <Col key={index} className="p-2 pb-0" xs={12} md={6} lg={4}>
        <video className="video-player" controls>
          <source src={`${video}`} type="video/mp4" />
          Your browser does not support the video element.
        </video>
      </Col>
    );
  });
  return media.length !== 0 ? (
    <Row>{media}</Row>
  ) : (
    <p className="mt-4 mb-5 text-center">Žádné video.</p>
  );
};

export default TeamMediaVideos;
